import { getMaterials } from './materials'
import advantageParts from '../data/advantage/parts'
import integrityParts from '../data/integrity/parts'

export const getPartsDataset = (type) => {
  let parts
  
  switch(type) {
    case 'integrity':
    case 'integrity-cattle':
      parts = integrityParts
      break
    case 'advantage':
    default:
      parts = advantageParts
  }
  
  return parts.map(a => {return {...a}})
}

export const getParts = (part, type, values) => {
  if (part) {
    if (!Array.isArray(part)) part = [part]
    part = part.map(p => getPartObject(p)).filter(n => n)
    if (!part.length) return []
  }

  if (!values) values = {}

  return (part ? part : getPartsDataset(type)).map(p => {
    p.weight = getPartWeight(p, type, values)
    p.galv = getPartGalv(p, type, values)
    p.steelMrk = getPartSteelMrk(p, type, values)
    p.steelDrop = getPartSteelDrop(p, type, values)
    p.steelCost = getPartSteelCost(p, type, values)
    p.fastener = getPartFastener(p, type, values)
    p.cover = getPartCover(p, type, values)
    p.totalMrk = getPartTotalMrk(p, type, values)

    p.materials = Array.from([...new Map(p.material.map(m => [m.id, m])).values()]).map(m => {
      const mat = getMaterials(m.id, type)
      if (mat.length) return mat[0]
      return null
    })

    return p
  })
}

export const getPartsByPartNumber = (partNumbers, type, values) => {
  let foundParts = null

  if (partNumbers) {
    if (!Array.isArray(partNumbers)) partNumbers = [partNumbers]
    foundParts = getPartsDataset(type).filter(p => partNumbers.includes(p.partNumber))
    if (!foundParts.length) return []
  }

  return getParts(foundParts, type, values)
}

export const getPartByPartNumber = (partNumber, type, values) => {
  let foundParts = getPartsByPartNumber(partNumber, type, values)
  if (foundParts.length) return foundParts[0]
  return false
}

export const getPartObject = (part, type) => {
  if (!part) return getParts(null, type)

  if (typeof part === 'object' && !Array.isArray(part) && part.id) {
    return part
  }

  part = getPartsDataset(type).find(p => p.id === part)

  if (part) return part
  return false
}

export const getPartWeight = (part, type, values) => {
  let weight = calculatePartMultipliers('weight', 'poundsPerUnit', part, type, values)

  if (weight && part.galv !== false) {
    weight *= 1.06
  }

  return weight
}

export const getPartGalv = (part, type, values) => {
  if (part.galv !== null) {
    return part.galv
  }

  part = getPartObject(part, type)

  if (!part || part.galv === false) {
    return 0
  }

  if (!part.weight) {
    part.weight = getPartWeight(part, type, values)
    if (!part.weight) part.weight = 0
  }

  const galv = part.weight * 0.34

  return galv
}

export const getPartSteelMrk = () => {
  return 0
}

export const getPartSteelDrop = (part, type, values) => {
  let steelDrop = calculatePartMultipliers('steelDrop', 'pricePerUnit', part, type, values)
  return steelDrop
}

export const getPartSteelCost = (part, type, values) => {
  let steelCost = calculatePartMultipliers('steelCost', 'pricePerUnit', part, type, values)
  return steelCost
}

export const getPartFastener = (part, type, values) => {
  let fastener = calculatePartMultipliers('fastener', 'pricePerUnit', part, type, values)
  return fastener
}

export const getPartCover = (part, type, values) => {
  let cover = calculatePartMultipliers('cover', 'pricePerUnit', part, type, values)
  return cover
}

export const getPartTotalMrk = (part, type, values) => {
  part = getPartObject(part, type)

  if (part === false) return 0

  part.galv = part.galv ?? getPartGalv(part, type, values) ?? 0
  part.steelMrk = part.steelMrk ?? getPartSteelMrk(part, type, values) ?? 0
  part.steelDrop = part.steelDrop ?? getPartSteelDrop(part, type, values) ?? 0
  part.steelCost = part.steelCost ?? getPartSteelCost(part, type, values) ?? 0
  part.fastener = part.fastener ?? getPartFastener(part, type, values) ?? 0
  part.cover = part.cover ?? getPartCover(part, type, values) ?? 0

  const totalMrk = part.galv + part.steelMrk + part.steelDrop + part.steelCost + part.fastener + part.cover

  return totalMrk
}

export const calculatePartMultipliers = (field, numberField, part, type, values) => {
  if (part[field] !== null) {
    return part[field]
  }

  part = getPartObject(part, type)

  if (!part || part[field] === false || !part.material || !part.material.length) {
    return false
  }

  const multiplierField = field + 'Multiplier'
  let value = 0
  let hasSetTrueValue = false
  
  // Check if this is an ENDURO-LOC type part
  const isEnduroLocType = part.material.some(m => 
    m[multiplierField] && 
    Array.isArray(m[multiplierField]) && 
    m[multiplierField].some(multi => 
      typeof multi === 'object' && 
      !Array.isArray(multi) &&
      multi.field === 'mainCoverTerminationStyle' && 
      multi.fieldValue === 'individual-bay-keder'
    )
  );

  part.material.forEach((m, index) => {
    if (!Object.prototype.hasOwnProperty.call(m, multiplierField) || m[multiplierField] === false) {
      return false
    }

    let material = getMaterials(m.id, type)

    if (material.length) {
      material = material[0]
    } else {
      return false
    }

    if (!Array.isArray(m[multiplierField])) {
      m[multiplierField] = [m[multiplierField]]
    }

    let add = material[numberField]

    m[multiplierField].forEach(multi => {
      if (multi === null) multi = 1

      if (Array.isArray(multi)) {
        let subAdd = 0

        multi.forEach(subMulti => {
          if (typeof subMulti === 'string') {
            if (values[subMulti]) {
              subAdd += values[subMulti]
            }
          } else {
            subAdd += subMulti
          }
        })

        if (subAdd) add *= subAdd
      } else if (typeof multi === 'object' && multi !== null) {
        if (values[multi.field] && values[multi.field] === multi.fieldValue) {
          if (Object.prototype.hasOwnProperty.call(multi, 'trueValue')) {
            value = multi.trueValue
            hasSetTrueValue = true
            return
          } else if (Object.prototype.hasOwnProperty.call(multi, 'trueMultiplier')) {
            if (multi.trueMultiplier === null) multi.trueMultiplier = 1
            add *= multi.trueMultiplier
          }
        } else {
          if (Object.prototype.hasOwnProperty.call(multi, 'falseValue')) {
            if (!hasSetTrueValue) {
              value = multi.falseValue
              hasSetTrueValue = true
            }
            return
          } else if (Object.prototype.hasOwnProperty.call(multi, 'falseMultiplier')) {
            if (multi.falseMultiplier === null) multi.falseMultiplier = 1
            add *= multi.falseMultiplier
          }
        }
      } else {
        add *= multi
      }
    })

    // If we have a trueValue, it overrides everything
    if (hasSetTrueValue) {
      return
    }

    // Special handling for ENDURO-LOC type parts
    if (field === 'fastener' && isEnduroLocType) {
      if (index === 0) value = add;
    } else {
      value += add;
    }
  })

  return value
}
