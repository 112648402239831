import { calculateLengthByRafterSpacing } from './'

export const manufacturedPartCalculations = {
  '41050127': values => Math.ceil(calculateLengthByRafterSpacing(values, ['46-0-int', '50-2-int', '62-2-int', '67-0-int'], 2)),
  '41030144': values => Math.ceil(calculateLengthByRafterSpacing(values, ['46-0-hp-int', '50-2-hp-int', '62-2-hp-int', '67-0-hp-int'], 2)),
  '41050126': values => Math.ceil(calculateLengthByRafterSpacing(values, ['46-0-int', '50-2-int', '62-2-int', '67-0-int', '46-0-hp-int', '50-2-hp-int', '62-2-hp-int', '67-0-hp-int'], 1)),
  '41030051': values => Math.ceil(calculateLengthByRafterSpacing(values, ['46-0-int', '46-0-hp-int'], 2)),
  '41050128': values => Math.ceil(calculateLengthByRafterSpacing(values, ['50-2-int', '50-2-hp-int'], 2)),
  '41050129': values => Math.ceil(calculateLengthByRafterSpacing(values, ['62-2-int', '62-2-hp-int'], 2)),
  '41050130': values => Math.ceil(calculateLengthByRafterSpacing(values, ['67-0-int', '67-0-hp-int'], 2)),
  '41030059': values => Math.ceil(calculateLengthByRafterSpacing(values, ['74-8-int', '88-0-int'], 2)),
  '41011137': values => Math.ceil(calculateLengthByRafterSpacing(values, '75-11-hp-int', 2)),
  '41010506': values => Math.ceil(calculateLengthByRafterSpacing(values, ['74-8-int', '75-11-hp-int'], 3)),
  '41010507': values => Math.ceil(calculateLengthByRafterSpacing(values, ['74-8-int', '75-11-hp-int'], 2)),
  '41050399': values => Math.ceil(calculateLengthByRafterSpacing(values, ['88-0-int', '98-1-hp-int'], 4)),
  '41050142': values => Math.ceil(calculateLengthByRafterSpacing(values, '96-9-int', 2)),
  '41050143': values => Math.ceil(calculateLengthByRafterSpacing(values, '98-1-hp-int', 2)),
  '41030052': values => Math.ceil(calculateLengthByRafterSpacing(values, ['96-9-int', '98-1-hp-int'], 2)),
  '41050144': values => Math.ceil(calculateLengthByRafterSpacing(values, ['96-9-int', '98-1-hp-int'], 1)),
  '41050145': values => Math.ceil(calculateLengthByRafterSpacing(values, ['96-9-int', '98-1-hp-int'], 2)),
  '41050140': values => Math.ceil(calculateLengthByRafterSpacing(values, ['114-4-int', '128-6-int'], 2)),
  '41050141': values => Math.ceil(calculateLengthByRafterSpacing(values, ['115-8-hp-int', '129-10-hp-int'], 2)),
  '41010943': values => Math.ceil(calculateLengthByRafterSpacing(values, ['114-4-int', '128-6-int', '115-8-hp-int', '129-10-hp-int'], 2)),
  '41010945': values => Math.ceil(calculateLengthByRafterSpacing(values, ['114-4-int', '128-6-int', '115-8-hp-int', '129-10-hp-int'], 1)),
  '41010944': values => Math.ceil(calculateLengthByRafterSpacing(values, '114-4-int', 4) ?? calculateLengthByRafterSpacing(values, '115-8-hp-int', 2)),
  '41030053': values => Math.ceil(calculateLengthByRafterSpacing(values, '115-8-hp-int', 2)),
  '41050388': values => Math.ceil(calculateLengthByRafterSpacing(values, '128-6-int', 4) ?? calculateLengthByRafterSpacing(values, '129-10-hp-int', 2)),
  '41030054': values => Math.ceil(calculateLengthByRafterSpacing(values, '129-10-hp-int', 4)),
  '41010508': values => calculatePurlinQty(values, 4),
  '41010509': values => calculatePurlinQty(values, 5),
  '41010510': values => calculatePurlinQty(values, 6),
  '41010511': values => calculatePurlinQty(values, 8),
  '41010512': values => calculatePurlinQty(values, 10),
  '41010513': values => calculatePurlinQty(values, 12),
  '41010514': values => calculatePurlinQty(values, 14),
  '41010515': values => calculatePurlinQty(values, 16),
  '41010516': values => calculatePurlinQty(values, 18),
  '41010517': values => calculatePurlinQty(values, 20),
  '41010518': values => calculateEndRafterBraceQty(values, 4),
  '41010519': values => calculateEndRafterBraceQty(values, 5),
  '41010520': values => calculateEndRafterBraceQty(values, 6),
  '41010521': values => calculateEndRafterBraceQty(values, 8),
  '41010522': values => calculateEndRafterBraceQty(values, 10),
  '41010523': values => calculateEndRafterBraceQty(values, 12),
  '41010524': values => calculateEndRafterBraceQty(values, 14),
  '41010525': values => calculateEndRafterBraceQty(values, 16),
  '41010526': values => calculateEndRafterBraceQty(values, 18),
  '41010527': values => calculateEndRafterBraceQty(values, 20),
  '41011039': values => calculatePurlinQty(values, 8, true),
  '41011038': values => calculatePurlinQty(values, 10, true),
  '41011037': values => calculatePurlinQty(values, 12, true),
  '41011036': values => calculatePurlinQty(values, 14, true),
  '41011035': values => calculatePurlinQty(values, 16, true),
  '41011034': values => calculatePurlinQty(values, 18, true),
  '41011033': values => calculatePurlinQty(values, 20, true),
  '41011040': values => calculateEndRafterBraceQty(values, 8, true),
  '41011041': values => calculateEndRafterBraceQty(values, 10, true),
  '41011042': values => calculateEndRafterBraceQty(values, 12, true),
  '41011043': values => calculateEndRafterBraceQty(values, 14, true),
  '41011044': values => calculateEndRafterBraceQty(values, 16, true),
  '41011045': values => calculateEndRafterBraceQty(values, 18, true),
  '41011046': values => calculateEndRafterBraceQty(values, 20, true),
  '41010941': (values, parts) => {
    switch(values.buildingWidth) {
      case '46-0-int':
      case '50-2-int':
        return Math.ceil(4 * (parts['41050127'] / 2))
      case '46-0-hp-int':
      case '50-2-hp-int':
        return Math.ceil(4 * (parts['41030144'] / 2))
      case '62-2-hp-int':
      case '67-0-hp-int':
        return Math.ceil(2 * (parts['41030144'] / 2))
      case '74-8-int':
        return Math.ceil(6 * (parts['41030059'] / 2))
      case '75-11-hp-int':
        return Math.ceil(6 * (parts['41011137'] / 2))
      case '88-0-int':
        return Math.ceil(8 * (parts['41030059'] / 2))
    }
  },
  '41010529': (values, parts) => {
    switch(values.buildingWidth) {
      case '46-0-int':
        return Math.ceil(4 * (parts['41050127'] / 2))
      case '46-0-hp-int':
        return Math.ceil(4 * (parts['41030144'] / 2))
      case '50-2-hp-int':
      case '62-2-hp-int':
      case '67-0-hp-int':
        return Math.ceil(2 * (parts['41030144'] / 2))
      case '74-8-int':
        return Math.ceil(4 * (parts['41030059'] / 2))
      case '75-11-hp-int':
        return Math.ceil(6 * (parts['41011137'] / 2))
      case '88-0-int':
        return Math.ceil(6 * (parts['41030059'] / 2))
    }
  },
  '41011138': (values, parts) => {
    switch(values.buildingWidth) {
      case '62-2-int':
      case '67-0-int':
        return Math.ceil(4 * (parts['41050127'] / 2))
      case '62-2-hp-int':
      case '67-0-hp-int':
        return Math.ceil(2 * (parts['41030144'] / 2))
    }
  },
  '41011139': (values, parts) => {
    switch(values.buildingWidth) {
      case '50-2-int':
      case '62-2-int':
      case '67-0-int':
        return Math.ceil(4 * (parts['41050127'] / 2))
      case '50-2-hp-int':
      case '62-2-hp-int':
      case '67-0-hp-int':
        return Math.ceil(2 * (parts['41030144'] / 2))
    }
  },
  '41045000': values => {
    switch(values.buildingWidth) {
      case '96-9-int':
      case '98-1-hp-int':
      case '115-8-hp-int':
      case '129-10-hp-int':
        return Math.ceil(((values.buildingLength / values.rafterSpacing) + 1) * 4)
      case '114-4-int':
      case '128-6-int':
        return Math.ceil(((values.buildingLength / values.rafterSpacing) + 1) * 8)
    }
  },
  '41055000': values => {
    switch(values.buildingWidth) {
      case '96-9-int':
      case '114-4-int':
      case '128-6-int':
        return Math.ceil(((values.buildingLength / values.rafterSpacing) + 1) * 6)
      case '98-1-hp-int':
        return Math.ceil(((values.buildingLength / values.rafterSpacing) + 1) * 8)
      case '115-8-hp-int':
      case '129-10-hp-int':
        return Math.ceil(((values.buildingLength / values.rafterSpacing) + 1) * 12)
    }
  },
  '42820000': values => 0,
  '41030471': values => {
    switch(values.buildingWidth) {
      case '96-9-int':
        return Math.ceil(((values.buildingLength / values.rafterSpacing) + 1) * 2)
    }
  },
  '41050562': values => {
    switch(values.buildingWidth) {
      case '114-4-int':
      case '128-6-int':
        return Math.ceil(((values.buildingLength / values.rafterSpacing) + 1) * 2)
    }
  },
  '41010504': (values, parts) => {
    if (values['wall-brackets'] != '8') {
      const legs = (parts['41050127'] + parts['41030144'] + parts['41030059'] + parts['41011137'])
      if (legs) return (legs - 4)
    }
  },
  '41010793': (values, parts) => {
    if (parts['41050127'] || parts['41030144'] || parts['41030059'] || parts['41011137']) return 4
  },
  '41030047': (values, parts) => {
    if (values['wall-brackets'] == '8') {
      const legs = (parts['41050127'] + parts['41030144'] + parts['41030059'] + parts['41011137'])
      if (legs) return (legs - 4)
    }
  },
  '41030032': (values, parts) => {
    if (values['wall-brackets'] == '4.5') {
      return (parts['41010504'] + parts['41030009'])
    }
  },
  '41030009': (values, parts) => {
    const legs = (parts['41050142'] + parts['41050143'] + parts['41050140'] + parts['41050141'])
    if (legs) return (legs - 4)
  },
  '41030010': (values, parts) => {
    if (parts['41050142'] || parts['41050143'] || parts['41050140'] || parts['41050141']) return 4
  },
  '41030139': values => {
    if (values['intermediate-single-hole-winch-brackets-between-rafters']) {
      return Math.ceil(values.buildingLength / values.rafterSpacing * 2)
    }
  },
  '41010027': values => {
    let multipliers = {
      '46-0-int': 2,
      '50-2-int': 3,
      '62-2-int': 3,
      '67-0-int': 3,
      '74-8-int': 4,
      '88-0-int': 4,
      '46-0-hp-int': 2,
      '50-2-hp-int': 2,
      '62-2-hp-int': 3,
      '67-0-hp-int': 3,
      '75-11-hp-int': 4
    }
    
    const multiplier = multipliers[values.buildingWidth]
    if (!multiplier) return
    return Math.ceil(((parseInt(values.buildingLength) + 2) * 2 / 24) + (multiplier * values.solidEndwallKit))
  },
  '41050063': values => {
    let multipliers = {
      '96-9-int': 5,
      '114-4-int': 6,
      '98-1-hp-int': 6,
      '115-8-hp-int': 6,
      '128-6-int': 7,
      '129-10-hp-int': 7,
    }
    
    let multiplier = multipliers[values.buildingWidth]
    if (multiplier) return Math.ceil(((parseInt(values.buildingLength) + 2) * 2 / 20) + (multiplier * values.solidEndwallKit))

    if (parseInt(values.rafterSpacing) >= 18) {
      multipliers = {
        '46-0-int': 3,
        '50-2-int': 3,
        '62-2-int': 4,
        '67-0-int': 4,
        '74-8-int': 4,
        '46-0-hp-int': 3,
        '50-2-hp-int': 3,
        '62-2-hp-int': 4,
        '67-0-hp-int': 4,
        '75-11-hp-int': 4
      }
      
      multiplier = multipliers[values.buildingWidth]
      if (!multiplier) return
      return Math.ceil(((parseInt(values.buildingLength) + 2) * 2 / 20) + (multiplier * values.solidEndwallKit))
    }
  },
  '91215000': values => calculateKederTrackQty(values),
  '91210000': values => calculateKederTrackQty(values, true),
  '64440000': values => calculateMainCoverQty(values, 'white', true),
  '64310000': values => calculateMainCoverQty(values, 'tan', true),
  '64450000': values => calculateMainCoverQty(values, 'green', true),
  '64435000': values => calculateMainCoverQty(values, 'white'),
  '64120000': values => calculateMainCoverQty(values, 'tan'),
  '64130000': values => calculateMainCoverQty(values, 'green'),
  '64150000': values => calculateMainCoverQty(values, 'red'),
  '64140000': values => calculateMainCoverQty(values, 'blue'),
  '63055012': values => calculateCapChannelQty(values, '20', 19.8),
  '63055020': values => calculateCapChannelQty(values, 'continuous', 1),
  '23560000': (values, parts) => values['5-8ths-threaded-rods'] ? ((parts['41030047'] + parts['41030032']) * 3) : null,
  '41090068': values => calculateCableAssemblyQty(values, {
    '46-0-int': [8, [4, 8, 16]],
    '50-2-int': [8, [4, 8, 16]],
    '62-2-int': [4, [4, 8, 16]],
    '67-0-int': [4, [4, 8, 16]],
    '46-0-hp-int': [8, [4, 8, 16]]
  }),
  '43775000': values => calculateCableAssemblyQty(values, {
    '46-0-int': [4, [4, 8, 16]],
    '50-2-int': [4, [4, 8, 16]],
    '62-2-int': [4, [5, 10]],
    '74-8-int': [4, [4, 8, 16]],
    '88-0-int': [6, [4, 8, 16]],
    '96-9-int': [4, [8, 16, 18]],
    '114-4-int': [4, [8, 16]],
    '128-6-int': [4, [8, 16]],
    '46-0-hp-int': [4, [4, 8, 16]],
    '50-2-hp-int': [12, [4, 8, 16]],
    '62-2-hp-int': [8, [4, 8, 16]],
    '67-0-hp-int': [4, [4, 8, 16]],
    '75-11-hp-int': [4, [4, 8, 16]],
    '98-1-hp-int': [4, [8, 16]]
  }),
  '41010550': values => calculateCableAssemblyQty(values, {
    '46-0-int': [8, [5, 10]],
    '50-2-int': [8, [5, 10]],
    '62-2-int': [4, [4, 8, 16]],
    '67-0-int': [4, [5, 10]],
    '74-8-int': [4, [18, 20]],
    '88-0-int': [6, [18, 20]],
    '96-9-int': [4, [8, 10, 16, 20]],
    '114-4-int': {8:16, 10:4, 16:16, 18:4, 20:4},
    '128-6-int': [4, [8, 10, 16, 18, 20]],
    '46-0-hp-int': [8, [5, 10]],
    '50-2-hp-int': [8, [5, 10]],
    '67-0-hp-int': [4, [4, 8, 16]],
    '75-11-hp-int': [4, [18]],
    '98-1-hp-int': [4, [8, 16, 18]],
    '115-8-hp-int': [20, [8, 16]],
    '129-10-hp-int': [4, [8, 16]]
  }),
  '41010913': values => calculateCableAssemblyQty(values, {
    '46-0-int': [4, [5, 10]],
    '50-2-int': [4, [5, 10]],
    '62-2-int': [4, [4, 5, 8, 10, 16]],
    '67-0-int': [8, [4, 8, 16]],
    '74-8-int': {4:8, 5:4, 8:8, 10:4, 16:8, 18:4},
    '88-0-int': {4:8, 5:6, 8:8, 10:6, 16:8},
    '96-9-int': {8:8, 10:4, 16:8, 18:12, 20:4},
    '114-4-int': {10:8, 18:16, 20:8},
    '128-6-int': {8:12, 16:12, 18:16},
    '46-0-hp-int': [4, [5, 10]],
    '50-2-hp-int': [4, [5, 10]],
    '62-2-hp-int': {4:4, 5:8, 8:4, 10:8, 16:4},
    '67-0-hp-int': {4:4, 5:8, 8:4, 10:8, 16:4},
    '75-11-hp-int': {4:4, 5:4, 8:8, 10:4, 16:8, 20:4},
    '98-1-hp-int': {8:8, 10:4, 16:8, 20:4},
    '115-8-hp-int': [20, [18]],
    '129-10-hp-int': {8:16, 10:4, 16:16, 20:4}
  }),
  '43780000': values => calculateCableAssemblyQty(values, {
    '46-0-int': [8, [6, 12]],
    '50-2-int': [12, [6, 12]],
    '62-2-int': [4, [5, 6, 10, 12]],
    '67-0-int': {5:8, 6:4, 10:8, 12:4},
    '74-8-int': {5:8, 10:8, 18:4, 20:8},
    '88-0-int': [8, [5, 10, 18, 20]],
    '96-9-int': {10:8, 12:4, 20:8},
    '114-4-int': {10:8, 12:4, 20:8},
    '128-6-int': {10:16, 12:4, 20:16},
    '46-0-hp-int': [8, [6, 12]],
    '50-2-hp-int': [8, [6, 12]],
    '62-2-hp-int': {5:4, 6:8, 10:4, 12:8},
    '67-0-hp-int': [4, [5, 6, 10, 12]],
    '75-11-hp-int': [8, [5, 10, 18]],
    '98-1-hp-int': {10:12, 12:4, 18:8, 20:12},
    '115-8-hp-int': [20, [10, 20]],
    '129-10-hp-int': [16, [10, 18, 20]]
  }),
  '41090045': values => calculateCableAssemblyQty(values, {
    '46-0-int': [4, [6, 12]],
    '62-2-int': [4, [6, 12]],
    '74-8-int': [4, [6, 12]],
    '88-0-int': [6, [6, 12]],
    '96-9-int': [4, [12]],
    '114-4-int': [16, [12]],
    '128-6-int': [4, [12]],
    '46-0-hp-int': [4, [6, 12]],
    '50-2-hp-int': [4, [6, 12]],
    '67-0-hp-int': [4, [6, 12]],
    '75-11-hp-int': {6:4, 12:4, 20:8},
    '98-1-hp-int': [4, [12]],
    '115-8-hp-int': [20, [12]],
    '129-10-hp-int': [4, [12]]
  }),
  '41630000': values => calculateCableAssemblyQty(values, {
    '46-0-int': [12, [14]],
    '50-2-int': [12, [14]],
    '62-2-int': [4, [6, 12, 14]],
    '67-0-int': {6:8, 12:8, 14:4},
    '74-8-int': {6:8, 12:8, 14:4},
    '88-0-int': {6:8, 12:8, 14:6},
    '96-9-int': {12:8, 14:4},
    '114-4-int': [4, [14]],
    '128-6-int': {12:12, 14:4},
    '46-0-hp-int': [12, [14]],
    '50-2-hp-int': [12, [14]],
    '62-2-hp-int': {6:4, 12:4, 14:8},
    '67-0-hp-int': [4, [6, 12, 14]],
    '75-11-hp-int': {6:8, 12:8, 14:4},
    '98-1-hp-int': {12:8, 14:4},
    '129-10-hp-int': [16, [12]]
  }),
  '41030008': values => calculateCableAssemblyQty(values, {
    '62-2-int': [8, [14]],
    '67-0-int': [8, [14]],
    '74-8-int': [4, [14]],
    '96-9-int': [12, [14]],
    '114-4-int': [16, [14]],
    '128-6-int': [16, [14]],
    '62-2-hp-int': [4, [14]],
    '67-0-hp-int': [8, [14]],
    '75-11-hp-int': [4, [14]],
    '98-1-hp-int': [12, [14]],
    '115-8-hp-int': [20, [14]],
    '129-10-hp-int': [4, [14]]
  }),
  '41030043': values => calculateCableAssemblyQty(values, {
    '74-8-int': [4, [14]],
    '88-0-int': [8, [14]],
    '75-11-hp-int': [4, [14]],
    '129-10-hp-int': [16, [14]]
  }),
  '41010939': values => {
    const baseNumbers = {
      '46-0-int': 10,
      '50-2-int': 10,
      '62-2-int': 10,
      '67-0-int': 10,
      '74-8-int': 10,
      '46-0-hp-int': 10,
      '50-2-hp-int': 10,
      '62-2-hp-int': 10,
      '67-0-hp-int': 10,
      '75-11-hp-int': 10,
      '88-0-int': 12
    }
    
    const baseNumber = baseNumbers[values.buildingWidth]
    if (!baseNumber) return
    return (baseNumber * (1 + Math.ceil(values.buildingLength / 100)))
  },
  '41010434': values => {
    const baseNumbers = {
      '96-9-int': 14,
      '114-4-int': 18,
      '128-6-int': 18,
      '98-1-hp-int': 14,
      '115-8-hp-int': 18,
      '129-10-hp-int': 18
    }
    
    const baseNumber = baseNumbers[values.buildingWidth]
    if (!baseNumber) return
    return (baseNumber * (1 + Math.ceil(values.buildingLength / 100)))
  },
  '31105000': values => (4 + (4 * Math.ceil(values.buildingLength / 100))),
  '41010297': (values, parts) => values['barrier-backing-plate'] ? (parts['23560000'] * (2 / 3)) : null,
  '41090180': (values, parts) => calculateEndWallColumnQty(values, parts, '74-8-int'),
  '41090181': (values, parts) => calculateEndWallColumnQty(values, parts, '74-8-int'),
  '41090190': (values, parts) => calculateEndWallColumnQty(values, parts, '75-11-hp-int'),
  '41090191': (values, parts) => calculateEndWallColumnQty(values, parts, '75-11-hp-int'),
  '41030357': (values, parts) => calculateEndWallColumnQty(values, parts, '88-0-int'),
  '41030356': (values, parts) => calculateEndWallColumnQty(values, parts, '88-0-int'),
  '41090182': (values, parts) => calculateEndWallColumnQty(values, parts, '96-9-int'),
  '41090183': (values, parts) => calculateEndWallColumnQty(values, parts, '96-9-int'),
  '41090192': (values, parts) => calculateEndWallColumnQty(values, parts, '98-1-hp-int'),
  '41090193': (values, parts) => calculateEndWallColumnQty(values, parts, '98-1-hp-int'),
  '41090184': (values, parts) => calculateEndWallColumnQty(values, parts, '114-4-int'),
  '41090185': (values, parts) => calculateEndWallColumnQty(values, parts, '114-4-int'),
  '41090186': (values, parts) => calculateEndWallColumnQty(values, parts, '114-4-int'),
  '41090194': (values, parts) => calculateEndWallColumnQty(values, parts, '115-8-hp-int'),
  '41090195': (values, parts) => calculateEndWallColumnQty(values, parts, '115-8-hp-int'),
  '41090196': (values, parts) => calculateEndWallColumnQty(values, parts, '115-8-hp-int'),
  '41090187': (values, parts) => calculateEndWallColumnQty(values, parts, '128-6-int'),
  '41090188': (values, parts) => calculateEndWallColumnQty(values, parts, '128-6-int'),
  '41090189': (values, parts) => calculateEndWallColumnQty(values, parts, '128-6-int'),
  '41090197': (values, parts) => calculateEndWallColumnQty(values, parts, '129-10-hp-int'),
  '41090198': (values, parts) => calculateEndWallColumnQty(values, parts, '129-10-hp-int'),
  '41090199': (values, parts) => calculateEndWallColumnQty(values, parts, '129-10-hp-int'),
  '41010357': values => calculateGirtClipQty(values, {
    '74-8-int': 2,
    '88-0-int': 4,
    '75-11-hp-int': 3,
    '96-9-int': 7,
    '98-1-hp-int': 7,
    '114-4-int': 9,
    '115-8-hp-int': 12,
    '128-6-int': 14,
    '129-10-hp-int': 14
  }),
  '41090070': values => calculateGirtClipQty(values, {
    '74-8-int': 2,
    '75-11-hp-int': 4
  }),
  '41011135': values => calculateGirtClipQty(values, {
    '98-1-hp-int': 2,
    '114-4-int': 2,
    '115-8-hp-int': 2
  }),
  '41011028': values => calculateGirtClipQty(values, {
    '129-10-hp-int': 2,
    '88-0-int': 2
  }),
  '41030041': values => calculateGirtClipQty(values, {
    '96-9-int': 2,
    '98-1-hp-int': 2,
    '114-4-int': 2,
    '129-10-hp-int': 2
  }),
  '41011134': values => calculateGirtClipQty(values, {
    '74-8-int': 2,
    '88-0-int': 2,
    '98-1-hp-int': 2,
    '128-6-int': 2,
    '129-10-hp-int': 2
  }),
  '41010359': values => calculateGirtClipQty(values, {
    '74-8-int': 2,
    '88-0-int': 4,
    '96-9-int': 2,
    '98-1-hp-int': 6,
    '114-4-int': 4,
    '115-8-hp-int': 2,
    '128-6-int': 2,
    '129-10-hp-int': 6
  }),
  '41011105': values => 0,
  '31010543': values => 0,
  '41030152': values => 0,
  '31070008': values => 0,
  '41030449': values => 0,
  '31030185': values => 0,
  '31030186': values => 0,
  '31010135': (values, parts) => (
    parts['41090180'] + 
    parts['41090181'] +
    parts['41090190'] +
    parts['41090191'] +
    parts['41030357'] +
    parts['41030356'] +
    parts['41090182'] +
    parts['41090183'] +
    parts['41090192'] +
    parts['41090193'] +
    parts['41090184'] +
    parts['41090185'] +
    parts['41090186'] +
    parts['41090194'] +
    parts['41090195'] +
    parts['41090196'] +
    parts['41090187'] +
    parts['41090188'] +
    parts['41090189'] +
    parts['41090197'] +
    parts['41090198'] +
    parts['41090199']
  ),
  '31010142L': (values, parts) => parts['31010135'],
  '31010142R': (values, parts) => parts['31010135'],
  '31995032': values => calculateGirtClipQty(values, {
    '74-8-int': 10,
    '88-0-int': 12,
    '75-11-hp-int': 14,
    '96-9-int': 16,
    '98-1-hp-int': 20,
    '114-4-int': 22,
    '115-8-hp-int': 26,
    '128-6-int': 30,
    '129-10-hp-int': 34
  }),
  '31190000': values => calculateGirtClipQty(values, {
    '46-0-hp-int': 4,
    '50-2-hp-int': 4,
    '62-2-hp-int': 4,
    '67-0-hp-int': 4,
    '46-0-int': 4,
    '50-2-int': 4,
    '62-2-int': 4,
    '67-0-int': 4
  }),
  '41325000': values => {
    const doorKits = (values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] + values['16-0-x-up-to-16-tall-gather-door-kit'] + values['14-0-x-up-to-16-tall-gather-door-kit'] + values['12-0-x-up-to-16-tall-gather-door-kit'])
    if (doorKits) return doorKits
    return ((values['19-7-1-2-door-opening-endwall-kit'] + values['16-0-door-opening-endwall-kit'] + values['14-0-door-opening-endwall-kit'] + values['12-0-door-opening-endwall-kit']) * 3)
  },
  '41010161': values => {
    const doorKits = (
      values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] +
      values['16-0-x-up-to-16-tall-gather-door-kit'] +
      values['14-0-x-up-to-16-tall-gather-door-kit'] +
      values['12-0-x-up-to-16-tall-gather-door-kit'] +
      values['19-7-1-2-door-opening-endwall-kit'] +
      values['16-0-door-opening-endwall-kit'] +
      values['14-0-door-opening-endwall-kit'] +
      values['12-0-door-opening-endwall-kit']
    )

    const baseNumbers = {
      '46-0-hp-int': 4,
      '50-2-hp-int': 6,
      '62-2-hp-int': 6,
      '67-0-hp-int': 6,
      '46-0-int': 4,
      '50-2-int': 4,
      '62-2-int': 4,
      '67-0-int': 6
    }

    const baseNumber = doorKits ? 2 : baseNumbers[values.buildingWidth]
    if (!baseNumber) return
    return (baseNumber * values.solidEndwallKit)
  },
  '41010162': values => {
    const baseNumbers = {
      '46-0-hp-int': 2,
      '50-2-hp-int': 2,
      '62-2-hp-int': 4,
      '67-0-hp-int': 4,
      '46-0-int': 2,
      '50-2-int': 2,
      '62-2-int': 4,
      '67-0-int': 2,
      '96-9-int': 1
    }

    const widths = [
      '46-0-hp-int',
      '50-2-hp-int',
      '62-2-hp-int',
      '67-0-hp-int',
      '46-0-int',
      '50-2-int',
      '62-2-int',
      '67-0-int',
      '74-8-int',
      '75-11-hp-int',
      '96-9-int',
      '98-1-hp-int',
      '114-4-int',
      '115-8-hp-int',
      '128-6-int',
      '129-10-hp-int'
    ]

    if (!widths.includes(values.buildingWidth)) return

    const baseNumber = baseNumbers[values.buildingWidth]
    let results = 0

    if (baseNumber) results = values.solidEndwallKit * baseNumber

    results += ((
      values['19-7-1-2-door-opening-endwall-kit'] +
      values['16-0-door-opening-endwall-kit'] +
      values['14-0-door-opening-endwall-kit'] +
      values['12-0-door-opening-endwall-kit']
    ) * 6)

    results += ((
      values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] +
      values['16-0-x-up-to-16-tall-gather-door-kit'] +
      values['14-0-x-up-to-16-tall-gather-door-kit'] +
      values['12-0-x-up-to-16-tall-gather-door-kit']
    ) * 2)

    return results
  },
  '41010168': values => 0,
  '41010163': values => {
    const baseNumbers = {
      '46-0-hp-int': 1,
      '50-2-hp-int': 4,
      '62-2-hp-int': 4,
      '67-0-hp-int': 2,
      '46-0-int': 4,
      '50-2-int': 4,
      '62-2-int': 4,
      '67-0-int': 4
    }

    const widths = [
      '46-0-hp-int',
      '50-2-hp-int',
      '62-2-hp-int',
      '67-0-hp-int',
      '46-0-int',
      '50-2-int',
      '62-2-int',
      '67-0-int',
      '74-8-int',
      '75-11-hp-int',
      '96-9-int',
      '98-1-hp-int',
      '114-4-int',
      '115-8-hp-int',
      '128-6-int',
      '129-10-hp-int'
    ]

    if (!widths.includes(values.buildingWidth)) return

    const baseNumber = baseNumbers[values.buildingWidth]
    let results = 0

    if (baseNumber) results = values.solidEndwallKit * baseNumber

    results += ((
      values['19-7-1-2-door-opening-endwall-kit'] +
      values['16-0-door-opening-endwall-kit'] +
      values['14-0-door-opening-endwall-kit'] +
      values['12-0-door-opening-endwall-kit']
    ) * 3)

    results += ((
      values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] +
      values['16-0-x-up-to-16-tall-gather-door-kit'] +
      values['14-0-x-up-to-16-tall-gather-door-kit'] +
      values['12-0-x-up-to-16-tall-gather-door-kit']
    ) * 1)

    return results
  },
  '41010167': values => {
    const multipliers = {
      '46-0-hp-int': 3,
      '50-2-hp-int': 2,
      '62-2-int': 2,
      '67-0-int': 2
    }
    
    const multiplier = multipliers[values.buildingWidth]
    if (!multiplier) return
    
    return (values.solidEndwallKit * multiplier)
  },
  '41030470': values => 0,
  '41010623': values => ['62-2-hp-int', '67-0-hp-int'].includes(values.buildingWidth) ? (values.solidEndwallKit * 2) : null,
  '41010626': values => ['62-2-hp-int', '67-0-hp-int'].includes(values.buildingWidth) ? (values.solidEndwallKit * 2) : null,
  '41010625': values => 0,
  '41010624': values => 0,
  '41010627': values => ['62-2-hp-int', '67-0-hp-int'].includes(values.buildingWidth) ? 4 : null,
  '41010164': values => (values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] * 6 + ((values['16-0-x-up-to-16-tall-gather-door-kit'] + values['14-0-x-up-to-16-tall-gather-door-kit'] + values['12-0-x-up-to-16-tall-gather-door-kit']) * 5)),
  '31050056': values => ((values['16-0-x-up-to-16-tall-gather-door-kit'] + values['14-0-x-up-to-16-tall-gather-door-kit'] + values['12-0-x-up-to-16-tall-gather-door-kit']) * 2),
  '31050057': values => (values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] * 2),
  '41030217': values => (values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] + values['16-0-x-up-to-16-tall-gather-door-kit'] + values['14-0-x-up-to-16-tall-gather-door-kit'] + values['12-0-x-up-to-16-tall-gather-door-kit']),
  '31010006': values => ((values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] + values['16-0-x-up-to-16-tall-gather-door-kit'] + values['14-0-x-up-to-16-tall-gather-door-kit'] + values['12-0-x-up-to-16-tall-gather-door-kit']) * 3),
  '41010165': values => values['walk-door-kit'],
  '41010166': values => (values['walk-door-kit'] * 2),
  'EWCFR46SWW': values => calculateEndWallCoverQty(values, '46-0-int', 'white', true),
  'EWCFR50SWW': values => calculateEndWallCoverQty(values, '50-2-int', 'white', true),
  'EWCFR62SWW': values => calculateEndWallCoverQty(values, '62-2-int', 'white', true),
  'EWCFR67SWW': values => calculateEndWallCoverQty(values, '67-0-int', 'white', true),
  'EWCFR74SWW': values => calculateEndWallCoverQty(values, '74-8-int', 'white', true),
  'EWCFR88SWW': values => calculateEndWallCoverQty(values, '88-0-int', 'white', true),
  'EWCFR96SWW': values => calculateEndWallCoverQty(values, '96-9-int', 'white', true),
  'EWCFR114SWW': values => calculateEndWallCoverQty(values, '114-4-int', 'white', true),
  'EWCFR128SWW': values => calculateEndWallCoverQty(values, '128-6-int', 'white', true),
  'EWCFR46HPWW': values => calculateEndWallCoverQty(values, '46-0-hp-int', 'white', true),
  'EWCFR50HPWW': values => calculateEndWallCoverQty(values, '50-2-hp-int', 'white', true),
  'EWCFR62HPWW': values => calculateEndWallCoverQty(values, '62-2-hp-int', 'white', true),
  'EWCFR67HPWW': values => calculateEndWallCoverQty(values, '67-0-hp-int', 'white', true),
  'EWCFR75HPWW': values => calculateEndWallCoverQty(values, '75-11-hp-int', 'white', true),
  'EWCFR98HPWW': values => calculateEndWallCoverQty(values, '98-1-hp-int', 'white', true),
  'EWCFR115HPWW': values => calculateEndWallCoverQty(values, '115-8-hp-int', 'white', true),
  'EWCFR129HPWW': values => calculateEndWallCoverQty(values, '129-10-hp-int', 'white', true),
  'EWCFR46STW': values => calculateEndWallCoverQty(values, '46-0-int', 'tan', true),
  'EWCFR50STW': values => calculateEndWallCoverQty(values, '50-2-int', 'tan', true),
  'EWCFR62STW': values => calculateEndWallCoverQty(values, '62-2-int', 'tan', true),
  'EWCFR67STW': values => calculateEndWallCoverQty(values, '67-0-int', 'tan', true),
  'EWCFR74STW': values => calculateEndWallCoverQty(values, '74-8-int', 'tan', true),
  'EWCFR88STW': values => calculateEndWallCoverQty(values, '88-0-int', 'tan', true),
  'EWCFR96STW': values => calculateEndWallCoverQty(values, '96-9-int', 'tan', true),
  'EWCFR114STW': values => calculateEndWallCoverQty(values, '114-4-int', 'tan', true),
  'EWCFR128STW': values => calculateEndWallCoverQty(values, '128-6-int', 'tan', true),
  'EWCFR46HPTW': values => calculateEndWallCoverQty(values, '46-0-hp-int', 'tan', true),
  'EWCFR50HPTW': values => calculateEndWallCoverQty(values, '50-2-hp-int', 'tan', true),
  'EWCFR62HPTW': values => calculateEndWallCoverQty(values, '62-2-hp-int', 'tan', true),
  'EWCFR67HPTW': values => calculateEndWallCoverQty(values, '67-0-hp-int', 'tan', true),
  'EWCFR75HPTW': values => calculateEndWallCoverQty(values, '75-11-hp-int', 'tan', true),
  'EWCFR98HPTW': values => calculateEndWallCoverQty(values, '98-1-hp-int', 'tan', true),
  'EWCFR115HPTW': values => calculateEndWallCoverQty(values, '115-8-hp-int', 'tan', true),
  'EWCFR129HPTW': values => calculateEndWallCoverQty(values, '129-10-hp-int', 'tan', true),
  'EWCFR46SGW': values => calculateEndWallCoverQty(values, '46-0-int', 'green', true),
  'EWCFR50SGW': values => calculateEndWallCoverQty(values, '50-2-int', 'green', true),
  'EWCFR62SGW': values => calculateEndWallCoverQty(values, '62-2-int', 'green', true),
  'EWCFR67SGW': values => calculateEndWallCoverQty(values, '67-0-int', 'green', true),
  'EWCFR74SGW': values => calculateEndWallCoverQty(values, '74-8-int', 'green', true),
  'EWCFR88SGW': values => calculateEndWallCoverQty(values, '88-0-int', 'green', true),
  'EWCFR96SGW': values => calculateEndWallCoverQty(values, '96-9-int', 'green', true),
  'EWCFR114SGW': values => calculateEndWallCoverQty(values, '114-4-int', 'green', true),
  'EWCFR128SGW': values => calculateEndWallCoverQty(values, '128-6-int', 'green', true),
  'EWCFR46HPGW': values => calculateEndWallCoverQty(values, '46-0-hp-int', 'green', true),
  'EWCFR50HPGW': values => calculateEndWallCoverQty(values, '50-2-hp-int', 'green', true),
  'EWCFR62HPGW': values => calculateEndWallCoverQty(values, '62-2-hp-int', 'green', true),
  'EWCFR67HPGW': values => calculateEndWallCoverQty(values, '67-0-hp-int', 'green', true),
  'EWCFR75HPGW': values => calculateEndWallCoverQty(values, '75-11-hp-int', 'green', true),
  'EWCFR98HPGW': values => calculateEndWallCoverQty(values, '98-1-hp-int', 'green', true),
  'EWCFR115HPGW': values => calculateEndWallCoverQty(values, '115-8-hp-int', 'green', true),
  'EWCFR129HPGW': values => calculateEndWallCoverQty(values, '129-10-hp-int', 'green', true),
  'EWC46SWW': values => calculateEndWallCoverQty(values, '46-0-int', 'white'),
  'EWC50SWW': values => calculateEndWallCoverQty(values, '50-2-int', 'white'),
  'EWC62SWW': values => calculateEndWallCoverQty(values, '62-2-int', 'white'),
  'EWC67SWW': values => calculateEndWallCoverQty(values, '67-0-int', 'white'),
  'EWC74SWW': values => calculateEndWallCoverQty(values, '74-8-int', 'white'),
  'EWC88SWW': values => calculateEndWallCoverQty(values, '88-0-int', 'white'),
  'EWC96SWW': values => calculateEndWallCoverQty(values, '96-9-int', 'white'),
  'EWC114SWW': values => calculateEndWallCoverQty(values, '114-4-int', 'white'),
  'EWC128SWW': values => calculateEndWallCoverQty(values, '128-6-int', 'white'),
  'EWC46HPWW': values => calculateEndWallCoverQty(values, '46-0-hp-int', 'white'),
  'EWC50HPWW': values => calculateEndWallCoverQty(values, '50-2-hp-int', 'white'),
  'EWC62HPWW': values => calculateEndWallCoverQty(values, '62-2-hp-int', 'white'),
  'EWC67HPWW': values => calculateEndWallCoverQty(values, '67-0-hp-int', 'white'),
  'EWC75HPWW': values => calculateEndWallCoverQty(values, '75-11-hp-int', 'white'),
  'EWC98HPWW': values => calculateEndWallCoverQty(values, '98-1-hp-int', 'white'),
  'EWC115HPWW': values => calculateEndWallCoverQty(values, '115-8-hp-int', 'white'),
  'EWC129HPWW': values => calculateEndWallCoverQty(values, '129-10-hp-int', 'white'),
  'EWC46STW': values => calculateEndWallCoverQty(values, '46-0-int', 'tan'),
  'EWC50STW': values => calculateEndWallCoverQty(values, '50-2-int', 'tan'),
  'EWC62STW': values => calculateEndWallCoverQty(values, '62-2-int', 'tan'),
  'EWC67STW': values => calculateEndWallCoverQty(values, '67-0-int', 'tan'),
  'EWC74STW': values => calculateEndWallCoverQty(values, '74-8-int', 'tan'),
  'EWC88STW': values => calculateEndWallCoverQty(values, '88-0-int', 'tan'),
  'EWC96STW': values => calculateEndWallCoverQty(values, '96-9-int', 'tan'),
  'EWC114STW': values => calculateEndWallCoverQty(values, '114-4-int', 'tan'),
  'EWC128STW': values => calculateEndWallCoverQty(values, '128-6-int', 'tan'),
  'EWC46HPTW': values => calculateEndWallCoverQty(values, '46-0-hp-int', 'tan'),
  'EWC50HPTW': values => calculateEndWallCoverQty(values, '50-2-hp-int', 'tan'),
  'EWC62HPTW': values => calculateEndWallCoverQty(values, '62-2-hp-int', 'tan'),
  'EWC67HPTW': values => calculateEndWallCoverQty(values, '67-0-hp-int', 'tan'),
  'EWC75HPTW': values => calculateEndWallCoverQty(values, '75-11-hp-int', 'tan'),
  'EWC98HPTW': values => calculateEndWallCoverQty(values, '98-1-hp-int', 'tan'),
  'EWC115HPTW': values => calculateEndWallCoverQty(values, '115-8-hp-int', 'tan'),
  'EWC129HPTW': values => calculateEndWallCoverQty(values, '129-10-hp-int', 'tan'),
  'EWC46SGW': values => calculateEndWallCoverQty(values, '46-0-int', 'green'),
  'EWC50SGW': values => calculateEndWallCoverQty(values, '50-2-int', 'green'),
  'EWC62SGW': values => calculateEndWallCoverQty(values, '62-2-int', 'green'),
  'EWC67SGW': values => calculateEndWallCoverQty(values, '67-0-int', 'green'),
  'EWC74SGW': values => calculateEndWallCoverQty(values, '74-8-int', 'green'),
  'EWC88SGW': values => calculateEndWallCoverQty(values, '88-0-int', 'green'),
  'EWC96SGW': values => calculateEndWallCoverQty(values, '96-9-int', 'green'),
  'EWC114SGW': values => calculateEndWallCoverQty(values, '114-4-int', 'green'),
  'EWC128SGW': values => calculateEndWallCoverQty(values, '128-6-int', 'green'),
  'EWC46HPGW': values => calculateEndWallCoverQty(values, '46-0-hp-int', 'green'),
  'EWC50HPGW': values => calculateEndWallCoverQty(values, '50-2-hp-int', 'green'),
  'EWC62HPGW': values => calculateEndWallCoverQty(values, '62-2-hp-int', 'green'),
  'EWC67HPGW': values => calculateEndWallCoverQty(values, '67-0-hp-int', 'green'),
  'EWC75HPGW': values => calculateEndWallCoverQty(values, '75-11-hp-int', 'green'),
  'EWC98HPGW': values => calculateEndWallCoverQty(values, '98-1-hp-int', 'green'),
  'EWC115HPGW': values => calculateEndWallCoverQty(values, '115-8-hp-int', 'green'),
  'EWC129HPGW': values => calculateEndWallCoverQty(values, '129-10-hp-int', 'green'),
  'EWC46SRW': values => calculateEndWallCoverQty(values, '46-0-int', 'red'),
  'EWC50SRW': values => calculateEndWallCoverQty(values, '50-2-int', 'red'),
  'EWC62SRW': values => calculateEndWallCoverQty(values, '62-2-int', 'red'),
  'EWC67SRW': values => calculateEndWallCoverQty(values, '67-0-int', 'red'),
  'EWC74SRW': values => calculateEndWallCoverQty(values, '74-8-int', 'red'),
  'EWC88SRW': values => calculateEndWallCoverQty(values, '88-0-int', 'red'),
  'EWC96SRW': values => calculateEndWallCoverQty(values, '96-9-int', 'red'),
  'EWC114SRW': values => calculateEndWallCoverQty(values, '114-4-int', 'red'),
  'EWC128SRW': values => calculateEndWallCoverQty(values, '128-6-int', 'red'),
  'EWC46HPRW': values => calculateEndWallCoverQty(values, '46-0-hp-int', 'red'),
  'EWC50HPRW': values => calculateEndWallCoverQty(values, '50-2-hp-int', 'red'),
  'EWC62HPRW': values => calculateEndWallCoverQty(values, '62-2-hp-int', 'red'),
  'EWC67HPRW': values => calculateEndWallCoverQty(values, '67-0-hp-int', 'red'),
  'EWC74HPRW': values => calculateEndWallCoverQty(values, '75-11-hp-int', 'red'),
  'EWC98HPRW': values => calculateEndWallCoverQty(values, '98-1-hp-int', 'red'),
  'EWC115HPRW': values => calculateEndWallCoverQty(values, '115-8-hp-int', 'red'),
  'EWC129HPRW': values => calculateEndWallCoverQty(values, '129-10-hp-int', 'red'),
  'EWC46SBW': values => calculateEndWallCoverQty(values, '46-0-int', 'blue'),
  'EWC50SBW': values => calculateEndWallCoverQty(values, '50-2-int', 'blue'),
  'EWC62SBW': values => calculateEndWallCoverQty(values, '62-2-int', 'blue'),
  'EWC67SBW': values => calculateEndWallCoverQty(values, '67-0-int', 'blue'),
  'EWC74SBW': values => calculateEndWallCoverQty(values, '74-8-int', 'blue'),
  'EWC88SBW': values => calculateEndWallCoverQty(values, '88-0-int', 'blue'),
  'EWC96SBW': values => calculateEndWallCoverQty(values, '96-9-int', 'blue'),
  'EWC114SBW': values => calculateEndWallCoverQty(values, '114-4-int', 'blue'),
  'EWC128SBW': values => calculateEndWallCoverQty(values, '128-6-int', 'blue'),
  'EWC46HPBW': values => calculateEndWallCoverQty(values, '46-0-hp-int', 'blue'),
  'EWC50HPBW': values => calculateEndWallCoverQty(values, '50-2-hp-int', 'blue'),
  'EWC62HPBW': values => calculateEndWallCoverQty(values, '62-2-hp-int', 'blue'),
  'EWC67HPBW': values => calculateEndWallCoverQty(values, '67-0-hp-int', 'blue'),
  'EWC75HPBW': values => calculateEndWallCoverQty(values, '75-11-hp-int', 'blue'),
  'EWC98HPBW': values => calculateEndWallCoverQty(values, '98-1-hp-int', 'blue'),
  'EWC115HPBW': values => calculateEndWallCoverQty(values, '115-8-hp-int', 'blue'),
  'EWC129HPBW': values => calculateEndWallCoverQty(values, '129-10-hp-int', 'blue'),
  'DC19': values => values['19-7-1-2-x-up-to-20-tall-gather-door-kit'],
  'DC16': values => values['16-0-x-up-to-16-tall-gather-door-kit'],
  'DC14': values => values['14-0-x-up-to-16-tall-gather-door-kit'],
  'DC12': values => values['12-0-x-up-to-16-tall-gather-door-kit'],
  '36235001': values => {
    const baseNumbers = {
      '46-0-int': 4,
      '50-2-int': 4,
      '62-2-int': 4,
      '67-0-int': 4,
      '74-8-int': 9,
      '88-0-int': 8,
      '96-9-int': 9,
      '114-4-int': 13,
      '128-6-int': 13,
      '46-0-hp-int': 4,
      '50-2-hp-int': 4,
      '62-2-hp-int': 4,
      '67-0-hp-int': 4,
      '75-11-hp-int': 9,
      '98-1-hp-int': 9,
      '115-8-hp-int': 13,
      '129-10-hp-int': 13
    }

    let results = 0

    if (values.numberOfMainCovers) {
      results = (values.buildingLength / parseInt(values.rafterSpacing))

      if (values.mainCoverTerminationStyle === 'individual-bay-keder') {
        results *= 2
      }

      results += 3

      if (values.mainCoverTerminationStyle === 'individual-bay-keder') {
        results -= 1
      }
    }

    if (values['extra-intermediate-single-hole-winches-between-rafters']) {
      results += (values.buildingLength / parseInt(values.rafterSpacing))
    }
    
    const baseNumber = baseNumbers[values.buildingWidth]
    if (!baseNumber) return

    results += (baseNumber * values.solidEndwallKit)

    results += (((
      values['19-7-1-2-door-opening-endwall-kit'] +
      values['16-0-door-opening-endwall-kit'] +
      values['14-0-door-opening-endwall-kit'] +
      values['12-0-door-opening-endwall-kit']
    ) * 2) + values['walk-door-kit'] * 2)

    results += ((
      values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] +
      values['16-0-x-up-to-16-tall-gather-door-kit'] +
      values['14-0-x-up-to-16-tall-gather-door-kit'] +
      values['12-0-x-up-to-16-tall-gather-door-kit']
    ) * 2)

    if (['sectional-keder', 'hybrid'].includes(values.mainCoverTerminationStyle)) {
      results += (values.numberOfMainCovers - 1)
    }

    return results
  },
  '36240001': values => {
    let results = 0

    if (values.numberOfMainCovers) {
      results = (values.buildingLength / parseInt(values.rafterSpacing))

      if (values.mainCoverTerminationStyle === 'individual-bay-keder') {
        results *= 2
      }

      results += 3

      if (values.mainCoverTerminationStyle === 'individual-bay-keder') {
        results -= 1
      }
    }

    if (values['extra-intermediate-single-hole-winches-between-rafters'] && parseInt(values.rafterSpacing) >= 8) {
      results += (values.buildingLength / parseInt(values.rafterSpacing))
    }

    if (['sectional-keder', 'hybrid'].includes(values.mainCoverTerminationStyle)) {
      results += (values.numberOfMainCovers - 1)
    }

    return results
  },
  '31540000': (values, parts) => (((parts['36235001'] + parts['36240001']) * 6) - 24),
  '27120000': (values, parts) => (addUpCableAssemblies(parts) + 1),
  '27035000': (values, parts) => ((addUpCableAssemblies(parts) * 3) + 1),
  '23570000': (values, parts) => {
    const insertsQty = addUpInserts(parts)
    if (insertsQty) return ((insertsQty * 6) + 6)
  },
  '25115000': (values, parts) => parts['23570000'],
  '25095000': (values, parts) => (parts['23570000'] * 2),
  '24105000': (values, parts) => parts['23570000'],
  '23050000': (values, parts) => {
    let results = addUpProAdvantageRafterBraces(parts)
    results += parts['31105000']
    results += (2 * addUpEndWallColumns(parts))
    results += (2 * parts['41090180'])
    results += (4 * parts['41090181'])
    results += (4 * parts['41090190'])
    results += (6 * parts['41090191'])
    results += (4 * parts['41030357'])
    results += (6 * parts['41030356'])
    results += (4 * parts['41090182'])
    results += (6 * parts['41090183'])
    results += (4 * parts['41090192'])
    results += (6 * parts['41090193'])
    results += (2 * parts['41090184'])
    results += (6 * parts['41090185'])
    results += (6 * parts['41090186'])
    results += (2 * parts['41090194'])
    results += (6 * parts['41090195'])
    results += (8 * parts['41090196'])
    results += (4 * parts['41090187'])
    results += (8 * parts['41090188'])
    results += (8 * parts['41090189'])
    results += (4 * parts['41090197'])
    results += (6 * parts['41090198'])
    results += (8 * parts['41090199'])
    results += (4 * parts['41010357'])
    results += (4 * parts['41090070'])
    results += (2 * parts['41011135'])
    results += (2 * parts['41011028'])
    results += (2 * parts['41030041'])
    results += (2 * parts['41011134'])
    results += 4
    return results
  },
  '23085000': (values, parts) => {
    let results = (2 * (addUpEndWallColumns(parts) + parts['41010504'] + parts['41010793'] + parts['41030047']))
    results += (3 * (parts['41011036'] + parts['41011035']))
    results += (parts['31010135'] * 4)
    results += 4
    return results
  },
  '23080000': values => 0,
  '23110000': values => 0,
  '23105000': (values, parts) => {
    let results = (4 * (parts['41010529'] + parts['41011139'] + parts['41030048'] + parts['41030049']))
    results += (parts['41011105'] * 2)
    results += parts['31010543']
    results += 4
    return results
  },
  '23130000': (values, parts) => {
    let results = (4 * addUpPurlins(parts))
    results += (2 * addUpEndRafterBraces(parts))
    results += (4 * parts['41010941'])
    results += (4 * parts['41011138'])
    results += (2 * parts['41010939'])
    results += (4 * (parts['41090180'] + parts['41090181'] + parts['41090190'] + parts['41090191'] + parts['41030357'] + parts['41030356']))
    results += (2 * parts['31190000'])
    results += (parts['41010627'] * 4)
    results += 4
    return results
  },
  '23120000': (values, parts) => {
    let results = ((
      values['19-7-1-2-door-opening-endwall-kit'] +
      values['16-0-door-opening-endwall-kit'] +
      values['14-0-door-opening-endwall-kit'] +
      values['12-0-door-opening-endwall-kit'] +
      values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] +
      values['16-0-x-up-to-16-tall-gather-door-kit'] +
      values['14-0-x-up-to-16-tall-gather-door-kit'] +
      values['12-0-x-up-to-16-tall-gather-door-kit']
    ) * 4)
    results += (2 * parts['31190000'])
    results += (2 * parts['41010161'])
    results += (2 * parts['41010162'])
    results += (4 * parts['41010627'])
    results += (2 * parts['41010166'])
    if (results) results += 4
    if (parts['41030152']) {
      results += (parts['41011105'] * 2)
    } else {
      results += parts['41011105']
    }
    results += (parts['41030152'] * 2)
    results += parts['31070008']
    return results
  },
  '23145000': values => 0,
  '23140000': (values, parts) => {
    let results = (4 * (parts['41011038'] + parts['41011037'] + parts['41011036'] + parts['41011035'] + parts['41011034'] + parts['41011033']))
    results += (2 * (parts['41011040'] + parts['41011041'] + parts['41011042'] + parts['41011043'] + parts['41011044'] + parts['41011045'] + parts['41011046']))
    results += (2 * parts['41010434'])
    results += (4 * (parts['41090182'] + parts['41090183'] + parts['41090192'] + parts['41090193'] + parts['41090184'] + parts['41090185'] + parts['41090186'] + parts['41090194'] + parts['41090195'] + parts['41090196'] + parts['41090187'] + parts['41090188'] + parts['41090189'] + parts['41090197'] + parts['41090198'] + parts['41090199']))
    if (results) results += 4
    results += parts['31070008']
    return results
  },
  '23650001': (values, parts) => parts['41030449'] ? ((parts['41030449'] * 2) + (parts['31030185'] + parts['31030186']) + 4) : null,
  '25015000': (values, parts) => addUpHalfInchHardware(parts),
  '25005000': (values, parts) => (parts['25015000'] * 2),
  '24015000': (values, parts) => parts['25015000'],
  '23295000': values => {
    if (values['ground-mount-cover']) {
      const multiplier = (values.mainCoverTerminationStyle === 'individual-bay-keder') ? 4 : 2
      return (((values.buildingLength / parseInt(values.rafterSpacing)) + 1) * multiplier)
    }
  },
  '25085000': (values, parts) => parts['23295000'],
  '25080000': (values, parts) => (parts['23295000'] * 2),
  '24095000': (values, parts) => parts['23295000'],
  '23465003': values => 0,
  '25075000': (values, parts) => (parts['23560000'] * 2 + parts['23295000'] * 2),
  '25090000': (values, parts) => (parts['23560000'] * 2 + parts['23295000']),
  '24120000': (values, parts) => (parts['23560000'] * 2 + parts['23295000']),
  '23190000': (values, parts) => (parts['41030032'] * 2),
  '23220000': (values, parts) => parts['31030185'] ? (parts['31030185'] + 1) : null,
  '23950008': (values, parts) => parts['31030186'] ? (parts['31030186'] + 1) : null,
  '24045000': (values, parts) => (parts['23190000'] + parts['23220000'] + parts['23950008']),
  '25035000': (values, parts) => (parts['23190000'] + parts['23220000'] + parts['23950008']),
  '25040000': (values, parts) => ((parts['23190000'] + parts['23220000'] + parts['23950008']) * 2),
  '27480002': (values, parts) => Math.ceil((
    parts['41010027'] * 8 +
    parts['41050063'] * 8 +
    parts['91215000'] * 36 +
    parts['91210000'] * 36 +
    parts['41010359'] * 12 +
    parts['31190000'] * 2 +
    20 * parts['41325000'] +
    8 * parts['41010161'] +
    8 * parts['41010162'] +
    8 * parts['41010168'] +
    2 * parts['41010627'] +
    17 * parts['31050056'] +
    21 * parts['31050057'] +
    8 * parts['31010006'] +
    13 * parts['41010165'] +
    parts['27180000'] * 1 +
    parts['41010166'] * 4 +
    parts['27160000'] * 6 +
    parts['36295000'] * 10
  ) * 1.05),
  '27480007': (values, parts) => ((parts['63055012'] * 2) + (parts['63055020'] ? ((values.numberOfMainCovers + 1) * 2) : 0)),
  '27075000': values => (values['walk-door-kit'] * 8),
  '31595000': values => {
    const baseNumbers = {
      '46-0-int': [48, 0, 69],
      '50-2-int': [51, 0, 75],
      '62-2-int': [61, 0, 89],
      '67-0-int': [64, 0, 95],
      '74-8-int': [71, 0, 81],
      '88-0-int': [82, 0, 91],
      '96-9-int': [91, 114, 0],
      '114-4-int': [104, 161, 0],
      '128-6-int': [115, 184, 0],
      '46-0-hp-int': [55, 0, 83],
      '50-2-hp-int': [58, 0, 87],
      '62-2-hp-int': [67, 0, 101],
      '67-0-hp-int': [71, 0, 107],
      '75-11-hp-int': [78, 0, 98],
      '98-1-hp-int': [98, 132, 0],
      '115-8-hp-int': [111, 185, 0],
      '129-10-hp-int': [122, 210, 0]
    }

    if (!values.numberOfMainCovers) return

    const baseNumber = baseNumbers[values.buildingWidth]
    if (!baseNumber) return

    let results = 0

    if (values.mainCoverTerminationStyle === 'hybrid') {
      if (values.numberOfMainCovers > 2) {
        results = (baseNumber[0] * ((values.numberOfMainCovers * 2) - 2))
      } else {
        results = (baseNumber[0] * values.numberOfMainCovers)
      }
    } else if (values.mainCoverTerminationStyle === 'sectional-keder' || values.mainCoverTerminationStyle === 'individual-Bay-keder') {
      results = (baseNumber[1] * values.solidEndwallKit)
    }

    results += ((baseNumber[2] * values.solidEndwallKit) + ((
      values['19-7-1-2-door-opening-endwall-kit'] +
      values['16-0-door-opening-endwall-kit'] +
      values['14-0-door-opening-endwall-kit'] +
      values['12-0-door-opening-endwall-kit'] +
      values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] +
      values['16-0-x-up-to-16-tall-gather-door-kit'] +
      values['14-0-x-up-to-16-tall-gather-door-kit'] +
      values['12-0-x-up-to-16-tall-gather-door-kit']
    ) * 14))

    return results
  },
  '31580010': values => {
    const baseNumbers = {
      '46-0-int': [8, 8],
      '50-2-int': [8, 8],
      '62-2-int': [10, 10],
      '67-0-int': [10, 10],
      '74-8-int': [11, 8],
      '88-0-int': [13, 8],
      '96-9-int': [14, 12],
      '114-4-int': [16, 12],
      '128-6-int': [18, 12],
      '46-0-hp-int': [9, 9],
      '50-2-hp-int': [9, 9],
      '62-2-hp-int': [11, 11],
      '67-0-hp-int': [11, 11],
      '75-11-hp-int': [12, 8],
      '98-1-hp-int': [15, 12],
      '115-8-hp-int': [18, 12],
      '129-10-hp-int': [19, 12]
    }

    if (!values.numberOfMainCovers) return

    const baseNumber = baseNumbers[values.buildingWidth]
    if (!baseNumber) return

    let results = 0

    if (values.mainCoverTerminationStyle === 'hybrid') {
      if (values.numberOfMainCovers > 2) {
        results = (baseNumber[0] * ((values.numberOfMainCovers * 2) - 2))
        
        results += ((
          values['19-7-1-2-door-opening-endwall-kit'] +
          values['16-0-door-opening-endwall-kit'] +
          values['14-0-door-opening-endwall-kit'] +
          values['12-0-door-opening-endwall-kit'] +
          values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] +
          values['16-0-x-up-to-16-tall-gather-door-kit'] +
          values['14-0-x-up-to-16-tall-gather-door-kit'] +
          values['12-0-x-up-to-16-tall-gather-door-kit']
        ) * 2)
      } else {
        results = (baseNumber[0] * values.numberOfMainCovers)
      }
    }

    results += (baseNumber[1] * values.solidEndwallKit)

    return results
  },
  '31585000': values => 0,
  '27480018': values => {
    const multipliers = {
      '74-8-int': 68,
      '88-0-int': 72,
      '96-9-int': 96,
      '114-4-int': 136,
      '128-6-int': 154,
      '75-11-hp-int': 80,
      '98-1-hp-int': 110,
      '115-8-hp-int': 154,
      '129-10-hp-int': 174
    }
    
    const multiplier = multipliers[values.buildingWidth]
    if (!multiplier) return
    if (multiplier === 96) {
      return ((multiplier * values.solidEndwallKit) + 10) * 4
    } else {
      return (multiplier * values.solidEndwallKit) * 4
    }
  },
  '31010012': (values, parts) => (
    (parts['41010027'] ? (values.numberOfMainCovers * 4) : 0) +
    (values.solidEndwallKit * 2) +
    ((
      values['19-7-1-2-door-opening-endwall-kit'] +
      values['16-0-door-opening-endwall-kit'] +
      values['14-0-door-opening-endwall-kit'] +
      values['12-0-door-opening-endwall-kit'] +
      values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] +
      values['16-0-x-up-to-16-tall-gather-door-kit'] +
      values['14-0-x-up-to-16-tall-gather-door-kit'] +
      values['12-0-x-up-to-16-tall-gather-door-kit'] +
      values['walk-door-kit']
    ) * 2)
  ),
  '41030251': (values, parts) => (parts['41050063'] ? (parts['41050063'] - 1) : 0),
  '27050000': values => Math.ceil(values.buildingLength / 100 + values.solidEndwallKit),
  '31565000': values => Math.ceil((
    values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] +
    values['16-0-x-up-to-16-tall-gather-door-kit'] +
    values['14-0-x-up-to-16-tall-gather-door-kit'] +
    values['12-0-x-up-to-16-tall-gather-door-kit']
  ) * 3),
  '23240000': (values, parts) => {
    return (parts['27265000'] * 3 + (
      values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] +
      values['16-0-x-up-to-16-tall-gather-door-kit'] +
      values['14-0-x-up-to-16-tall-gather-door-kit'] +
      values['12-0-x-up-to-16-tall-gather-door-kit']
    ))
  },
  '25055000': (values, parts) => (parts['23240000'] * 2),
  '24060000': (values, parts) => parts['23240000'],
  '23285000': (values, parts) => {
    return (parts['31565000'] * 3 + (
      values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] +
      values['16-0-x-up-to-16-tall-gather-door-kit'] +
      values['14-0-x-up-to-16-tall-gather-door-kit'] +
      values['12-0-x-up-to-16-tall-gather-door-kit']
    ))
  },
  '25070000': (values, parts) => (parts['23285000'] * 2),
  '24085000': (values, parts) => parts['23285000'],
  '27025000': values => {
    return ((
      values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] +
      values['16-0-x-up-to-16-tall-gather-door-kit'] +
      values['14-0-x-up-to-16-tall-gather-door-kit'] +
      values['12-0-x-up-to-16-tall-gather-door-kit']
    ) * 6)
  },
  '27005000': values => {
    return ((
      values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] +
      values['16-0-x-up-to-16-tall-gather-door-kit'] +
      values['14-0-x-up-to-16-tall-gather-door-kit'] +
      values['12-0-x-up-to-16-tall-gather-door-kit']
    ) * 100)
  },
  '27180000': (values, parts) => {
    return (
      parts['41010165'] * 13 +
      parts['41010164'] * 2 +
      values.numberOfMainCovers * 4 +
      parts['36295001'] * 10 +
      values.solidEndwallKit * 2 +
      ((
        values['19-7-1-2-door-opening-endwall-kit'] +
        values['16-0-door-opening-endwall-kit'] +
        values['14-0-door-opening-endwall-kit'] +
        values['12-0-door-opening-endwall-kit']
      ) * 2) +
      ((
        values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] +
        values['16-0-x-up-to-16-tall-gather-door-kit'] +
        values['14-0-x-up-to-16-tall-gather-door-kit'] +
        values['12-0-x-up-to-16-tall-gather-door-kit'] +
        values['walk-door-kit']
      ) * 2)
    )
  },
  '27265000': values => (
    values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] +
    values['16-0-x-up-to-16-tall-gather-door-kit'] +
    values['14-0-x-up-to-16-tall-gather-door-kit'] +
    values['12-0-x-up-to-16-tall-gather-door-kit']
  ),
  '31510000': values => values['walk-door-kit'],
  '31515000': values => values['walk-door-kit'],
  '27160000': values => 0,
  '31555000': values => 1,
  '31795000': values => {
    const baseNumbers = {
      '46-0-int': [48, 48],
      '50-2-int': [51, 51],
      '62-2-int': [61, 61],
      '67-0-int': [64, 71],
      '74-8-int': [71, 0],
      '88-0-int': [82, 0],
      '96-9-int': [91, 0],
      '114-4-int': [104, 0],
      '128-6-int': [115, 0],
      '46-0-hp-int': [55, 55],
      '50-2-hp-int': [58, 58],
      '62-2-hp-int': [67, 67],
      '67-0-hp-int': [71, 71],
      '75-11-hp-int': [78, 0],
      '98-1-hp-int': [98, 0],
      '115-8-hp-int': [111, 0],
      '129-10-hp-int': [122, 0]
    }

    if (!values.numberOfMainCovers) return

    const baseNumber = baseNumbers[values.buildingWidth]
    if (!baseNumber) return

    let results = 0

    if (values.mainCoverTerminationStyle === 'hybrid') {
      if (values.numberOfMainCovers > 2) {
        results = (baseNumber[0] * ((values.numberOfMainCovers * 2) - 2))
      } else {
        results = (baseNumber[0] * values.numberOfMainCovers)
      }
    }

    results += (baseNumber[1] * values.solidEndwallKit)

    return results
  },
  '27090000': (values, parts) => Math.ceil((parts['91215000'] + parts['91210000']) / 40),
  '27045001': (values, parts) => Math.ceil(parts['63055012'] / 5),
  '27200000': (values, parts) => {
    if (values['building-to-foundation-fasteners'] && values.foundationMaterial === 'concrete') {
      return (addUpEndWallColumns(parts) * 4)
    }
  },
  '27465000': (values, parts) => {
    if (values['building-to-foundation-fasteners'] && values.foundationMaterial === 'concrete') {
      let results = (parts['36235001'] + parts['36240001'] + parts['41010162'] * 2)
      
      if (['46-0-int', '50-2-int', '62-2-int', '67-0-int', '46-0-hp-int', '50-2-hp-int', '62-2-hp-int', '67-0-hp-int'].includes(values.buildingWidth)) {
        results += (4 * values.solidEndwallKit)
      }
      
      results += ((
        values['19-7-1-2-door-opening-endwall-kit'] +
        values['16-0-door-opening-endwall-kit'] +
        values['14-0-door-opening-endwall-kit'] +
        values['12-0-door-opening-endwall-kit'] +
        values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] +
        values['16-0-x-up-to-16-tall-gather-door-kit'] +
        values['14-0-x-up-to-16-tall-gather-door-kit'] +
        values['12-0-x-up-to-16-tall-gather-door-kit']
      ) * 4)
                                           
      return results
    }
  },
  '27215000': (values, parts) => {
    if (values['building-to-foundation-fasteners'] && values.foundationMaterial === 'concrete') {
      const neededParts = (parts['41010504'] + parts['41010793'] + parts['41030047'] + parts['41030009'] + parts['41030010'])
      let results = ((neededParts * 4) + 4)
      if (values['5-8ths-threaded-rods']) {
        results -= (neededParts * 2)
      }
      return results
    }
  },
  '27430000': (values, parts) => {
    if (values['building-to-foundation-fasteners'] && values.foundationMaterial === 'wood') {
      return (addUpEndWallColumns(parts) * 4)
    }
  },
  '27430001': (values, parts) => {
    if (values['building-to-foundation-fasteners'] && values.foundationMaterial === 'wood') {
      return ((parts['36235001'] + parts['36240001'] + parts['41010162']) * 2)
    }
  },
  '27430004': (values, parts) => {
    if (values['building-to-foundation-fasteners'] && values.foundationMaterial === 'wood') {
      const neededParts = (parts['41010504'] + parts['41010793'] + parts['41030047'] + parts['41030009'] + parts['41030010'])
      let results = ((neededParts * 4) + 4)
      if (values['5-8ths-threaded-rods']) {
        results -= (neededParts * 2)
      }
      return results
    }
  },
  '23590000': (values, parts) => {
    return ((
      parts['32220000'] +
      parts['32220010'] +
      parts['32220011'] +
      parts['32220002'] +
      parts['32220001']
    ) * 4)
  },
  '25020000': (values, parts) => parts['23590000'],
  '25010000': (values, parts) => parts['23590000'],
  '24030000': (values, parts) => parts['23590000'],
  '41030048': values => calculateRubRailQty(values, 18),
  '41030049': values => calculateRubRailQty(values, 20),
  '32220000': values => calculateRubRailQty(values, 8),
  '32220010': values => calculateRubRailQty(values, 10),
  '32220011': values => calculateRubRailQty(values, 12),
  '32220002': values => calculateRubRailQty(values, 14),
  '32220001': values => calculateRubRailQty(values, 16),
  '27480001': values => values['barrier-backing-plate'] ? Math.ceil(((values.buildingLength / 5) + 1) * 2) : null,
  '36295000': values => values.ventType === '24x30-vent-with-wood-frame-kit' ? values.ventCount : 0,
  '36295001': (values, parts) => parts['36295000'],
  'MV2430': values => values.ventType === '24x30-mesh-vent' ? values.ventCount : 0,
  'MV4848': values => values.ventType === '48x48-mesh-vent' ? values.ventCount : 0,
  '36175000': values => values['batten-strip-and-screws'] ? Math.ceil(((values.buildingLength + 2) * 2) / 8 + ((values.solidEndwallKit * values.buildingWidthNumber) / 8)) : 0,
  '27480008': (values, parts) => (parts['36175000'] * 8),
  '63055007': values => 4,
  '90035000': values => 1,
  '90030000-ITP': values => 1,
  '90030000-ITPC': values => 0,
  '90030000-IPN': values => 1,
  '90030000-1380C': values => values.buildingWidth === '38-0-int' ? 1 : null,
  '90030000-1460': values => values.buildingWidth === '46-0-int' ? 1 : null,
  '90030000-1460HP': values => values.buildingWidth === '46-0-hp-int' ? 1 : null,
  '90030000-1460CB': values => 0,
  '90030000-1460CSTD': values => 0,
  '90030000-1460CSTDB': values => 0,
  '90030000-1460CHP': values => 0,
  '90030000-1460CHPSTD': values => 0,
  '90030000-1460CHPB': values => 0,
  '90030000-1502': values => values.buildingWidth === '50-2-int' ? 1 : null,
  '90030000-1502HP': values => values.buildingWidth === '50-2-hp-int' ? 1 : null,
  '90030000-1502CB': values => 0,
  '90030000-1502CSTD': values => 0,
  '90030000-1502CSTDB': values => 0,
  '90030000-1502CHP': values => 0,
  '90030000-1502CHPSTD': values => 0,
  '90030000-1502CHPB': values => 0,
  '90030000-1622': values => values.buildingWidth === '62-2-int' ? 1 : null,
  '90030000-1622HP': values => values.buildingWidth === '62-2-hp-int' ? 1 : null,
  '90030000-1622CB': values => 0,
  '90030000-1622CSTD': values => 0,
  '90030000-1622CSTDB': values => 0,
  '90030000-1622CHP': values => 0,
  '90030000-1622CHPSTD': values => 0,
  '90030000-1622CHPB': values => values.buildingWidth === '67-0-int' ? 1 : null,
  '90030000-1670HP': values => values.buildingWidth === '67-0-hp-int' ? 1 : null,
  '90030000-1670CB': values => 0,
  '90030000-1670CSTD': values => 0,
  '90030000-1670CSTDB': values => 0,
  '90030000-1670CHP': values => 0,
  '90030000-1670CHPSTD': values => 0,
  '90030000-1670CHPB': values => 0,
  '90030000-1748': values => values.buildingWidth === '74-8-int' ? 1 : null,
  '90030000-17511HP': values => values.buildingWidth === '75-11-hp-int' ? 1 : null,
  '90030000-1748CB': values => 0,
  '90030000-1748CSTD': values => 0,
  '90030000-1748CSTDB': values => 0,
  '90030000-17511CHP': values => 0,
  '90030000-17511CHPSTD': values => 0,
  '90030000-17511CHPB': values => 0,
  '90030000-1969': values => values.buildingWidth === '96-9-int' ? 1 : null,
  '90030000-1969C': values => 0,
  '90030000-1981HP': values => values.buildingWidth === '98-1-hp-int' ? 1 : null,
  '90030000-1981HPC': values => 0,
  '90030000-11144': values => values.buildingWidth === '114-4-int' ? 1 : null,
  '90030000-11158HP': values => values.buildingWidth === '115-8-hp-int' ? 1 : null,
  '90030000-11286': values => values.buildingWidth === '128-6-int' ? 1 : null,
  '90030000-112910HP': values => values.buildingWidth === '129-10-hp-int' ? 1 : null,
  '90030000-1B': values => 1,
  '90030000-1BC': values => 0,
  '90030000-1C': values => ['46-0-int', '50-2-int', '62-2-int', '67-0-int', '74-8-int', '46-0-hp-int', '50-2-hp-int', '62-2-hp-int', '67-0-hp-int', '75-11-hp-int'].includes(values.buildingWidth) ? 1 : null,
  '900-30000-1D': values => ['96-9-int', '98-1-hp-int', '114-4-int', '115-8-hp-int', '128-6-int', '129-10-hp-int'].includes(values.buildingWidth) ? 1 : null,
  '900-30000-1E460B': values => 0,
  '900-30000-1E502B': values => 0,
  '900-30000-1E622B': values => 0,
  '900-30000-1E670B': values => 0,
  '900-30000-1E748B': values => 0,
  '900-30000-1E380HPB': values => 0,
  '900-30000-1E460HPB': values => 0,
  '900-30000-1E502HPB': values => 0,
  '900-30000-1E622HPB': values => 0,
  '900-30000-1E670HPB': values => 0,
  '900-30000-1E7511HPB': values => 0,
  '900-30000-1F': values => 0,
  '900-30000-02380': values => 0,
  '900-30000-0246-27511': values => ['46-0-int', '50-2-int', '62-2-int', '67-0-int', '74-8-int', '46-0-hp-int', '50-2-hp-int', '62-2-hp-int', '67-0-hp-int', '75-11-hp-int'].includes(values.buildingWidth) ? 1 : null,
  '900-30000-0246-27511CB': values => 0,
  '900-30000-0246-27511CB-BP': values => 0,
  '900-30000-02969-212910': values => ['96-9-int', '98-1-hp-int', '114-4-int', '115-8-hp-int', '128-6-int', '129-10-hp-int'].includes(values.buildingWidth) ? 1 : null,
  '900-30000-02969-212910C': values => 0,
  '90030000-3380C': values => 0,
  '90030000-3460': values => values.buildingWidth === '46-0-int' ? 1 : null,
  '90030000-3460HP': values => values.buildingWidth === '46-0-hp-int' ? 1 : null,
  '90030000-3460CB': values => 0,
  '90030000-3460CSTD': values => 0,
  '90030000-3460CSTDB': values => 0,
  '90030000-3460CHP': values => 0,
  '90030000-3460CHPSTD': values => 0,
  '90030000-3460CHPB': values => 0,
  '90030000-3502': values => values.buildingWidth === '50-2-int' ? 1 : null,
  '90030000-3502HP': values => values.buildingWidth === '50-2-hp-int' ? 1 : null,
  '90030000-3502CB': values => 0,
  '90030000-3502CSTD': values => 0,
  '90030000-3502CSTDB': values => 0,
  '90030000-3502CHP': values => 0,
  '90030000-3502CHPSTD': values => 0,
  '90030000-3502CHPB': values => 0,
  '90030000-3622': values => values.buildingWidth === '62-2-int' ? 1 : null,
  '90030000-3622HP': values => values.buildingWidth === '62-2-hp-int' ? 1 : null,
  '90030000-3622CB': values => 0,
  '90030000-3622CSTD': values => 0,
  '90030000-3622CSTDB': values => 0,
  '90030000-3622CHP': values => 0,
  '90030000-3622CHPSTD': values => 0,
  '90030000-3622CHPB': values => 0,
  '90030000-3670': values => values.buildingWidth === '67-0-int' ? 1 : null,
  '90030000-3670HP': values => values.buildingWidth === '67-0-hp-int' ? 1 : null,
  '90030000-3670CB': values => 0,
  '90030000-3670CSTD': values => 0,
  '90030000-3670CSTDB': values => 0,
  '90030000-3670CHP': values => 0,
  '90030000-3670CHPSTD': values => 0,
  '90030000-3670CHPB': values => 0,
  '90030000-3748': values => values.buildingWidth === '74-8-int' ? 1 : null,
  '90030000-37511HP': values => values.buildingWidth === '75-11-hp-int' ? 1 : null,
  '90030000-3748CB': values => 0,
  '90030000-3748CSTD': values => 0,
  '90030000-3748CSTDB': values => 0,
  '90030000-37511CHP': values => 0,
  '90030000-37511CHPSTD': values => 0,
  '90030000-37511CHPB': values => 0,
  '90030000-3969': values => values.buildingWidth === '96-9-int' ? 1 : null,
  '90030000-3969C': values => 0,
  '90030000-3981HP': values => values.buildingWidth === '98-1-hp-int' ? 1 : null,
  '90030000-3981HPC': values => 0,
  '90030000-31144': values => values.buildingWidth === '114-4-int' ? 1 : null,
  '90030000-31158HP': values => values.buildingWidth === '115-8-hp-int' ? 1 : null,
  '90030000-31286': values => values.buildingWidth === '128-6-int' ? 1 : null,
  '90030000-312910HP': values => values.buildingWidth === '129-10-hp-int' ? 1 : null,
  '90030000-4A': values => ['46-0-int', '50-2-int', '62-2-int', '67-0-int', '74-8-int', '46-0-hp-int', '50-2-hp-int', '62-2-hp-int', '67-0-hp-int', '75-11-hp-int'].includes(values.buildingWidth) ? 1 : null,
  '90030000-4B': values => ['96-9-int', '98-1-hp-int', '114-4-int', '115-8-hp-int', '128-6-int', '129-10-hp-int'].includes(values.buildingWidth) ? 1 : null,
  '90030000-4C': values => 0,
  '90030000-4BEAMS': values => 0,
  '90030000-4BPB': values => 0,
  '90030000-EW-460A': values => values.buildingWidth === '46-0-int' && values.solidEndwallKit ? 1 : null,
  '90030000-EW-460B': values => values.buildingWidth === '46-0-int' && (values['19-7-1-2-door-opening-endwall-kit'] || values['19-7-1-2-x-up-to-20-tall-gather-door-kit']) ? 1 : null,
  '90030000-EW-460C': values => 0,
  '90030000-EW-460D': values => 0,
  '90030000-EW-502A': values => values.buildingWidth === '50-2-int' && values.solidEndwallKit ? 1 : null,
  '90030000-EW-502B': values => values.buildingWidth === '50-2-int' && (values['19-7-1-2-door-opening-endwall-kit'] || values['19-7-1-2-x-up-to-20-tall-gather-door-kit']) ? 1 : null,
  '90030000-EW-502C': values => 0,
  '90030000-EW-502D': values => 0,
  '90030000-EW-622A': values => values.buildingWidth === '62-2-int' && values.solidEndwallKit ? 1 : null,
  '90030000-EW-622B': values => values.buildingWidth === '62-2-int' && (values['19-7-1-2-door-opening-endwall-kit'] || values['19-7-1-2-x-up-to-20-tall-gather-door-kit']) ? 1 : null,
  '90030000-EW-622C': values => 0,
  '90030000-EW-622D': values => 0,
  '90030000-EW-670A': values => values.buildingWidth === '67-0-int' && values.solidEndwallKit ? 1 : null,
  '90030000-EW-670B': values => values.buildingWidth === '67-0-int' && (values['19-7-1-2-door-opening-endwall-kit'] || values['19-7-1-2-x-up-to-20-tall-gather-door-kit']) ? 1 : null,
  '90030000-EW-670C': values => 0,
  '90030000-EW-670D': values => 0,
  '90030000-EW460HPA': values => values.buildingWidth === '46-0-hp-int' && values.solidEndwallKit ? 1 : null,
  '90030000-EW460HPB': values => values.buildingWidth === '46-0-hp-int' && (values['19-7-1-2-door-opening-endwall-kit'] || values['19-7-1-2-x-up-to-20-tall-gather-door-kit']) ? 1 : null,
  '90030000-EW-460HPC': values => 0,
  '90030000-EW502HPA': values => values.buildingWidth === '50-2-hp-int' && values.solidEndwallKit ? 1 : null,
  '90030000-EW502HPB': values => values.buildingWidth === '50-2-hp-int' && (values['19-7-1-2-door-opening-endwall-kit'] || values['19-7-1-2-x-up-to-20-tall-gather-door-kit']) ? 1 : null,
  '90030000-EW-502HPC': values => 0,
  '90030000-EW-502HPD': values => 0,
  '90030000-EW622HPA': values => values.buildingWidth === '62-2-hp-int' && values.solidEndwallKit ? 1 : null,
  '90030000-EW622HPB': values => values.buildingWidth === '62-2-hp-int' && (values['19-7-1-2-door-opening-endwall-kit'] || values['19-7-1-2-x-up-to-20-tall-gather-door-kit']) ? 1 : null,
  '90030000-EW-622HPC': values => 0,
  '90030000-EW-622HPD': values => 0,
  '90030000-EW670HPA': values => values.buildingWidth === '67-0-hp-int' && values.solidEndwallKit ? 1 : null,
  '90030000-EW670HPB': values => values.buildingWidth === '67-0-hp-int' && (values['19-7-1-2-door-opening-endwall-kit'] || values['19-7-1-2-x-up-to-20-tall-gather-door-kit']) ? 1 : null,
  '90030000-EW-670HPC': values => 0,
  '90030000-EW-670HPD': values => 0,
  '90030000-EW748A': values => values.buildingWidth === '74-8-int' && values.solidEndwallKit ? 1 : null,
  '90030000-EW-748C': values => 0,
  '90030000-EW-748D': values => 0,
  '90030000-EW7511HPA': values => values.buildingWidth === '75-11-hp-int' && values.solidEndwallKit ? 1 : null,
  '90030000-EW-7511HPC': values => 0,
  '90030000-EW-7511HPD': values => 0,
  '90030000-EW969A': values => values.buildingWidth === '96-9-int' && values.solidEndwallKit ? 1 : null,
  '90030000-EW-969B': values => 0,
  '90030000-EW-969C': values => 0,
  '90030000-EW-969D': values => 0,
  '90030000-EW981HPA': values => values.buildingWidth === '98-1-hp-int' && values.solidEndwallKit ? 1 : null,
  '90030000-EW-981HPB': values => 0,
  '90030000-EW-981HPC': values => 0,
  '90030000-EW-981HPD': values => 0,
  '90030000-EW1144A': values => values.buildingWidth === '114-4-int' && values.solidEndwallKit ? 1 : null,
  '90030000-EW1158HPA': values => values.buildingWidth === '115-8-hp-int' && values.solidEndwallKit ? 1 : null,
  '90030000-EW1286A': values => values.buildingWidth === '128-6-int' && values.solidEndwallKit ? 1 : null,
  '90030000-EW12910HPA': values => values.buildingWidth === '129-10-hp-int' && values.solidEndwallKit ? 1 : null,
  '90030000-EW-1B': values => values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] && ['96-9-int', '98-1-hp-int', '114-4-int', '115-8-hp-int', '128-6-int', '129-10-hp-int'].includes(values.buildingWidth) ? 1 : null,
  '90030000-EW-1C': values => values['16-0-x-up-to-16-tall-gather-door-kit'] ? 1 : null,
  '90030000-EW-1D': values => values['14-0-x-up-to-16-tall-gather-door-kit'] ? 1 : null,
  '90030000-EW-1E': values => values['12-0-x-up-to-16-tall-gather-door-kit'] ? 1 : null,
  '9003000-EW-2A46-67': values => values.solidEndwallKit && ['46-0-int', '50-2-int', '62-2-int', '67-0-int', '74-8-int', '46-0-hp-int', '50-2-hp-int', '62-2-hp-int', '67-0-hp-int', '75-11-hp-int'].includes(values.buildingWidth) ? 1 : null,
  '9003000-EW-2B748-129': values => values.solidEndwallKit && ['96-9-int', '98-1-hp-int', '114-4-int', '115-8-hp-int', '128-6-int', '129-10-hp-int'].includes(values.buildingWidth) ? 1 : null,
  '90030000-CP01': values => 0,
  '90030000-CP01A': values => 0,
  '90030000-CP01B': values => 0,
  '90030000-CP01C': values => 0,
  '90030000-CP01D': values => 0,
  '90030000-CP01WIK': values => !values['ground-mount-cover'] && values.mainCoverTerminationStyle === 'individual-bay-keder' ? 1 : null,
  '9003000-CP01GIK': values => values['ground-mount-cover'] && values.mainCoverTerminationStyle === 'individual-bay-keder' ? 1 : null,
  '90030000-CP01WSK': values => !values['ground-mount-cover'] && values.mainCoverTerminationStyle === 'sectional-keder' && !values.numberOfMainCovers ? 1 : null,
  '90030000-CP01WSK2': values => !values['ground-mount-cover'] && values.mainCoverTerminationStyle === 'sectional-keder' && values.numberOfMainCovers ? 1 : null,
  '90030000-CP01GSK': values => values['ground-mount-cover'] && values.mainCoverTerminationStyle === 'sectional-keder' && !values.numberOfMainCovers ? 1 : null,
  '90030000-CP01GSK2': values => values['ground-mount-cover'] && values.mainCoverTerminationStyle === 'sectional-keder' && values.numberOfMainCovers ? 1 : null,
  '90030000-CP01WH': values => !values['ground-mount-cover'] && values.mainCoverTerminationStyle === 'hybrid' && !values.numberOfMainCovers ? 1 : null,
  '90030000-CP01WH2': values => !values['ground-mount-cover'] && values.mainCoverTerminationStyle === 'hybrid' && values.numberOfMainCovers ? 1 : null,
  '90030000-CP01GH': values => values['ground-mount-cover'] && values.mainCoverTerminationStyle === 'hybrid' && !values.numberOfMainCovers ? 1 : null,
  '90030000-CP01GH2': values => values['ground-mount-cover'] && values.mainCoverTerminationStyle === 'hybrid' && values.numberOfMainCovers ? 1 : null,
  '90030000-CP02W46STD': values => !values['ground-mount-cover'] && values.solidEndwallKit && ['46-0-int', '50-2-int', '62-2-int', '67-0-int'].includes(values.buildingWidth) ? 1 : null,
  '90030000-CP02W46HP': values => !values['ground-mount-cover'] && values.solidEndwallKit && ['46-0-hp-int', '50-2-hp-int', '62-2-hp-int', '67-0-hp-int'].includes(values.buildingWidth) ? 1 : null,
  '90030000-CP02W128': values => !values['ground-mount-cover'] && values.solidEndwallKit && ['74-8-int', '75-11-hp-int', '96-9-int', '114-4-int', '128-6-int'].includes(values.buildingWidth) ? 1 : null,
  '90030000-CP02G46STD': values => values['ground-mount-cover'] && values.solidEndwallKit && ['46-0-int', '50-2-int', '62-2-int', '67-0-int'].includes(values.buildingWidth) ? 1 : null,
  '90030000-CP02G46HP': values => values['ground-mount-cover'] && values.solidEndwallKit && ['46-0-hp-int', '50-2-hp-int', '62-2-hp-int', '67-0-hp-int'].includes(values.buildingWidth) ? 1 : null,
  '90030000-CP02G128': values => values['ground-mount-cover'] && values.solidEndwallKit && ['74-8-int', '75-11-hp-int', '96-9-int', '114-4-int', '128-6-int'].includes(values.buildingWidth) ? 1 : null,
  '90030000-CP0346W': values => calculateDoorOpeningEndCoverMountDrawingQty(values, ['46-0-int', '50-2-int', '62-2-int', '67-0-int', '74-8-int']),
  '90030000-CP0346HPW': values => calculateDoorOpeningEndCoverMountDrawingQty(values, ['46-0-hp-int', '50-2-hp-int', '62-2-hp-int', '67-0-hp-int', '75-11-hp-int']),
  '90030000-CP0396W': values => calculateDoorOpeningEndCoverMountDrawingQty(values, ['96-9-int', '98-1-hp-int', '114-4-int', '115-8-hp-int', '128-6-int', '129-10-hp-int']),
  '90030000-CP0346W': values => calculateDoorOpeningEndCoverMountDrawingQty(values, ['46-0-int', '50-2-int', '62-2-int', '67-0-int', '74-8-int'], true),
  '90030000-CP0346HPW': values => calculateDoorOpeningEndCoverMountDrawingQty(values, ['46-0-hp-int', '50-2-hp-int', '62-2-hp-int', '67-0-hp-int', '75-11-hp-int'], true),
  '90030000-CP0396W': values => calculateDoorOpeningEndCoverMountDrawingQty(values, ['96-9-int', '98-1-hp-int', '114-4-int', '115-8-hp-int', '128-6-int', '129-10-hp-int'], true),
  '90030000-CP0346C': values => 0,
  '90030000-CP0346HPC': values => 0,
  '90030000-CP0396C': values => 0,
  '90030000-CP4A': values => values['walk-door-kit'] ? 1 : null,
  '90030000-CP4B': values => (values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] || values['16-0-x-up-to-16-tall-gather-door-kit'] || values['14-0-x-up-to-16-tall-gather-door-kit'] || values['12-0-x-up-to-16-tall-gather-door-kit']) ? 1 : null,
  '90030000-CP4C': values => (values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] || values['16-0-x-up-to-16-tall-gather-door-kit'] || values['14-0-x-up-to-16-tall-gather-door-kit'] || values['12-0-x-up-to-16-tall-gather-door-kit']) ? 1 : null,
  '90030000-CP4D': values => (values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] || values['16-0-x-up-to-16-tall-gather-door-kit'] || values['14-0-x-up-to-16-tall-gather-door-kit'] || values['12-0-x-up-to-16-tall-gather-door-kit']) ? 1 : null,
  '90030000-CP4E': values => 0,
  '90030000-CP4F': values => 0,
  '90030000-CP4G': values => 0,
  '90030000-CP4H': values => 0,
  '90030000-CP4I': values => 0,
  '90030000-CP05KSTD': values => ['46-0-int', '50-2-int', '62-2-int', '67-0-int', '74-8-int', '96-9-int', '114-4-int', '128-6-int'].includes(values.buildingWidth) ? 1 : null,
  '90030000-CP05KHP': values => ['46-0-hp-int', '50-2-hp-int', '62-2-hp-int', '67-0-hp-int', '75-11-hp-int', '98-1-hp-int', '115-8-hp-int', '129-10-hp-int'].includes(values.buildingWidth) ? 1 : null,
  '90030000-CP05KA': (values, parts) => parts['63055012'] ? 1 : null
}

const calculatePurlinQty = (values, rafterSpacing, highProfile) => {
  if (parseInt(values.rafterSpacing) === rafterSpacing) {
    let multipliers = {
      '46-0-int': 7,
      '50-2-int': 7,
      '62-2-int': 9,
      '67-0-int': 9,
      '74-8-int': 11,
      '88-0-int': 13,
      '46-0-hp-int': 7,
      '50-2-hp-int': 7,
      '62-2-hp-int': 9,
      '67-0-hp-int': 9,
      '75-11-hp-int': 11
    }
    
    if (highProfile) {
      multipliers = {
        '96-9-int': 11,
        '114-4-int': 13,
        '128-6-int': 13,
        '98-1-hp-int': 11,
        '115-8-hp-int': 13,
        '129-10-hp-int': 13
      }
    }

    const multiplier = multipliers[values.buildingWidth]
    if (!multiplier) return
    return Math.ceil((parseInt(values.buildingLength) / rafterSpacing) * multiplier)
  }
}

const calculateEndRafterBraceQty = (values, rafterSpacing, highProfile) => {
  if (parseInt(values.rafterSpacing) === rafterSpacing) {
    let multipliers = {
      '46-0-int': 7,
      '50-2-int': 7,
      '67-0-int': 7,
      '62-2-int': 9,
      '74-8-int': 9,
      '88-0-int': 11,
      '46-0-hp-int': 5,
      '50-2-hp-int': 5,
      '62-2-hp-int': 7,
      '67-0-hp-int': 7,
      '75-11-hp-int': 9
    }
    
    if (highProfile) {
      multipliers = {
        '96-9-int': 11,
        '114-4-int': 13,
        '128-6-int': 13,
        '98-1-hp-int': 11,
        '115-8-hp-int': 13,
        '129-10-hp-int': 13
      }
    }

    const multiplier = multipliers[values.buildingWidth]
    if (!multiplier) return
    // return Math.ceil((values.numberOfMainCovers + 1) * multiplier)
    return Math.ceil((values.buildingLength/12 + 1) * multiplier)
  }
}

const calculateKederTrackQty = (values, highProfile) => {
  let dividers = {
    '46-0-int': 72,
    '50-2-int': 77,
    '62-2-int': 91,
    '67-0-int': 96,
    '74-8-int': 106,
    '88-0-int': 122,
    '46-0-hp-int': 82,
    '50-2-hp-int': 87,
    '62-2-hp-int': 101,
    '67-0-hp-int': 106,
    '75-11-hp-int': 117
  }
  
  if (highProfile) {
    dividers = {
      '96-9-int': 136,
      '114-4-int': 156,
      '128-6-int': 172,
      '98-1-hp-int': 146,
      '115-8-hp-int': 166,
      '129-10-hp-int': 182
    }
  }

  const breakpoints = {
    4: 64,
    5: 70,
    6: 60,
    8: 64,
    10: 70,
    12: 60,
    14: 70,
    16: 64,
    18: 54,
    20: 60
  }

  const divider = dividers[values.buildingWidth]
  if (!divider) return

  switch(values.mainCoverTerminationStyle) {
    case 'individual-bay-keder':
      return Math.ceil((parseInt(values.buildingLength) / parseInt(values.rafterSpacing) + 1) * Math.ceil(divider / 23.5))
    case 'sectional-keder':
    case 'hybrid':
      const breakpoint = breakpoints[parseInt(values.rafterSpacing)]
      if (!breakpoint) return
    case 'sectional-keder':
      return Math.ceil((values.buildingLength <= breakpoint ? 2 : 1 + (values.buildingLength / breakpoint)) * Math.ceil(divider / 23.5))
    case 'hybrid':
      return Math.ceil((values.buildingLength <= breakpoint ? 1 : 2) * Math.ceil(divider / 23.5))
  }
}

const calculateMainCoverQty = (values, color, fireRetardant) => {
  if (values.mainCoversColor !== color) return
  if (fireRetardant && values.coverMaterial !== 'enduro-loc-fr') return
  if (!fireRetardant && values.coverMaterial === 'enduro-loc-fr') return

  const baseNumbers = {
    '46-0-int': 71.417,
    '50-2-int': 76.17,
    '62-2-int': 90.084,
    '67-0-int': 95.67,
    '74-8-int': 106,
    '88-0-int': 122,
    '96-9-int': 135.917,
    '114-4-int': 155.84,
    '128-6-int': 171.84,
    '46-0-hp-int': 81.417,
    '50-2-hp-int': 86.17,
    '62-2-hp-int': 100.084,
    '67-0-hp-int': 105.67,
    '75-11-hp-int': 116.34,
    '98-1-hp-int': 146,
    '115-8-hp-int': 165.917,
    '129-10-hp-int': 181.917,
  }
  
  const additions = {
    '1-0': 1,
    '1-6': 1.5,
    '2-0': 2,
    '2-6': 2.5,
    '3-0': 3,
    '3-6': 3.5,
    '4-0': 4,
    '4-6': 4.5,
    '5-0': 5,
    '5-6': 5.5,
    '6-0': 6,
    '6-6': 6.5,
    '7-0': 7,
    '7-6': 7.5,
    '8-0': 8
  }

  const baseNumber = baseNumbers[values.buildingWidth]
  if (!baseNumber) return
  
  const addition = additions[values.sidewallCoverBelowZeroDistance]
  if (!addition) return

  return Math.ceil(((baseNumber + addition * 2) * (values.buildingLength + 4)) * 1.082)
}

const calculateCapChannelQty = (values, kederCapChannelValue, divider) => {
  if (values.kederCapChannel === kederCapChannelValue) {
    const baseNumbers = {
      '46-0-int': 72,
      '50-2-int': 77,
      '62-2-int': 91,
      '67-0-int': 96,
      '74-8-int': 106,
      '88-0-int': 122,
      '96-9-int': 136,
      '114-4-int': 156,
      '128-6-int': 172,
      '46-0-hp-int': 82,
      '50-2-hp-int': 87,
      '62-2-hp-int': 101,
      '67-0-hp-int': 106,
      '75-11-hp-int': 117,
      '98-1-hp-int': 146,
      '115-8-hp-int': 166,
      '129-10-hp-int': 182,
    }

    const breakpoints = {
      4: 64,
      5: 70,
      6: 60,
      8: 64,
      10: 70,
      12: 60,
      14: 70,
      16: 64,
      18: 54,
      20: 60
    }
    
    const baseNumber = baseNumbers[values.buildingWidth]
    if (!baseNumber) return
    
    switch(values.mainCoverTerminationStyle) {
      case 'individual-bay-keder':
        return Math.ceil(Math.ceil(Math.ceil((values.buildingLength / parseInt(values.rafterSpacing)) + 1) * (baseNumber + 5)) / divider)
      case 'hybrid':
        return Math.ceil(Math.ceil((baseNumber + 5) * 2) / divider)
      case 'sectional-keder':
        const breakpoint = breakpoints[parseInt(values.rafterSpacing)]
        if (!breakpoint) return
        return Math.ceil(Math.ceil((values.buildingLength <= breakpoint ? 2 : 1 + (values.buildingLength / breakpoint)) * Math.ceil(baseNumber + 5)) / divider)
    }
  }
}

const calculateCableAssemblyQty = (values, conditions) => {
  const condition = conditions[values.buildingWidth]
  if (!condition) return
  
  let baseNumber

  if (Array.isArray(condition)) {
    if (!condition[1].includes(parseInt(values.rafterSpacing))) return
    baseNumber = condition[0]
  } else {
    if (!Object.keys(condition).includes(values.rafterSpacing)) return
    baseNumber = condition[values.rafterSpacing]
  }

  if (!baseNumber) return

  return (baseNumber * (1 + Math.ceil(values.buildingLength / 100)))
}

const calculateEndWallColumnQty = (values, parts, targetWidth) => {
  if (values.buildingWidth === targetWidth) {
    return (2 * values.solidEndwallKit)
  }
}

const calculateGirtClipQty = (values, baseNumbers) => {
  const baseNumber = baseNumbers[values.buildingWidth]
  if (!baseNumber) return
  return (baseNumber * values.solidEndwallKit)
}

const calculateEndWallCoverQty = (values, targetWidth, color, fireRetardant) => {
  if (values.buildingWidth !== targetWidth) return
  if (values.mainCoversColor !== color) return
  if (fireRetardant && values.coverMaterial !== 'enduro-loc-fr') return
  if (!fireRetardant && values.coverMaterial === 'enduro-loc-fr') return
  return values.solidEndwallKit
}

const calculateRubRailQty = (values, targetRafterSpacing) => {
  const rafterSpacing = parseInt(values.rafterSpacing)
  if (values['rubrail-and-fasteners'] && rafterSpacing === targetRafterSpacing) {
    return Math.ceil((values.buildingLength / rafterSpacing) * 2)
  }
}

const calculateDoorOpeningEndCoverMountDrawingQty = (values, targetWidths, groundMount) => {
  if (groundMount && !values['ground-mount-cover']) return
  if (!groundMount && values['ground-mount-cover']) return
  if (!targetWidths.includes(values.buildingWidth)) return
  if (!(
    values['19-7-1-2-door-opening-endwall-kit'] ||
    values['16-0-door-opening-endwall-kit'] ||
    values['14-0-door-opening-endwall-kit'] ||
    values['12-0-door-opening-endwall-kit'] ||
    values['19-7-1-2-x-up-to-20-tall-gather-door-kit'] ||
    values['16-0-x-up-to-16-tall-gather-door-kit'] ||
    values['14-0-x-up-to-16-tall-gather-door-kit'] ||
    values['12-0-x-up-to-16-tall-gather-door-kit']
  )) return

  return 1
}

const addUpCableAssemblies = (parts) => {
  return (
    parts['41090068'] +
    parts['43775000'] +
    parts['41010550'] +
    parts['41010913'] +
    parts['43780000'] +
    parts['41090045'] +
    parts['41630000'] +
    parts['41030008'] +
    parts['41030043']
  )
}

const addUpInserts = (parts) => {
  return (
    parts['41045000'] +
    parts['41055000'] +
    parts['42820000'] +
    parts['41030471'] +
    parts['41050562']
  )
}

const addUpHalfInchHardware = (parts) => {
  return (
    parts['23050000'] +
    parts['23085000'] +
    parts['23080000'] +
    parts['23110000'] +
    parts['23105000'] +
    parts['23130000'] +
    parts['23120000'] +
    parts['23145000'] +
    parts['23140000'] +
    parts['23650001']
  )
}

const addUpEndWallColumns = (parts) => {
  return (
    parts['41090180'] +
    parts['41090181'] +
    parts['41090190'] +
    parts['41090191'] +
    parts['41030357'] +
    parts['41030356'] +
    parts['41090182'] +
    parts['41090183'] +
    parts['41090192'] +
    parts['41090193'] +
    parts['41090184'] +
    parts['41090185'] +
    parts['41090186'] +
    parts['41090194'] +
    parts['41090195'] +
    parts['41090196'] +
    parts['41090187'] +
    parts['41090188'] +
    parts['41090189'] +
    parts['41090197'] +
    parts['41090198'] +
    parts['41090199']
  )
}

const addUpPurlins = (parts) => {
  return (
    parts['41010508'] +
    parts['41010509'] +
    parts['41010510'] +
    parts['41010511'] +
    parts['41010512'] +
    parts['41010513'] +
    parts['41010514'] +
    parts['41010515'] +
    parts['41010516'] +
    parts['41010517']
  )
}

const addUpEndRafterBraces = (parts) => {
  return (
    parts['41010518'] +
    parts['41010519'] +
    parts['41010520'] +
    parts['41010521'] +
    parts['41010522'] +
    parts['41010523'] +
    parts['41010524'] +
    parts['41010525'] +
    parts['41010526'] +
    parts['41010527']
  )
}

const addUpProAdvantageRafterBraces = (parts) => {
  return (
    parts['41011040'] +
    parts['41011041'] +
    parts['41011042'] +
    parts['41011043'] +
    parts['41011044'] +
    parts['41011045'] +
    parts['41011046']
  )
}