import React, { useState, useEffect } from 'react'
import advantageBuildingOptions from '../../data/advantage/options'
import integrityBuildingOptions from '../../data/integrity/options'
import integritySnowWindLoadData from '../../data/integrity/snow-wind-load'
import advantageSnowWindLoadData from '../../data/advantage/snow-wind-load'
import {
  Button,
  Col,
  Form,
  Input,
  PageHeader,
  Row,
  Select,
  Table
} from 'antd'

const BuildingInputs = ({ buildingType, onBuildingTypeChange, onBuildingWidthChange }) => {
  const [buildingOptions, setBuildingOptions] = useState([])

  useEffect(() => {
    switch (buildingType) {
      case 'integrity':
        setBuildingOptions(integrityBuildingOptions)
        break
      case 'advantage':
      default:
        setBuildingOptions(advantageBuildingOptions)
        break
    }
  }, [buildingType])

  return (
    <div>
      <Row gutter={32}>
        <Col span={6}>
          <Form.Item name="buildingType" label="Building Type">
            <Select defaultValue={buildingType} onChange={onBuildingTypeChange} options={[
              { label: 'Advantage', value: 'advantage' },
              { label: 'Integrity', value: 'integrity' },
            ]} />
          </Form.Item>
        </Col>
        {buildingOptions
          .filter((opt) => opt.name === 'buildingWidth')
          .map((opt, i) => {
            return (
              <Col key={i} span={7}>
                <Form.Item name={opt.name} label={opt.label} tooltip={opt.info}>
                  <Select options={opt.options} onChange={onBuildingWidthChange} />
                </Form.Item>
              </Col>
            )
          })}
      </Row>
    </div>
  )
}

const SnowWindLoadChart = ({ buildingType, buildingWidth }) => {
  const [snowWindLoadData, setSnowWindLoadData] = useState([])

  useEffect(() => {
    let data

    switch (buildingType) {
      case 'integrity':
        data = integritySnowWindLoadData.filter(
          (option) => option.buildingWidth === buildingWidth
        )
        break
      case 'advantage':
      default:
        data = advantageSnowWindLoadData.filter(
          (option) => option.buildingWidth === buildingWidth
        )
        break
    }
    setSnowWindLoadData(data)
  }, [buildingType, buildingWidth])

  const renderCell = (text) => {
    if (text === null) {
      return { props: { style: { color: 'white', backgroundColor: '#000128' } }, children: 'N/A' };
    }
    return text;
  };

  const columns = [
    { title: 'Rafter Centers', dataIndex: 'rafterSpacing', key: 'rafterSpacing', width: 100 },
    { title: 'GSL (psf) 1.0', dataIndex: 'gsl1', key: 'gsl1', width: 100, render: renderCell },
    { title: 'WL (mph) Cat. II', dataIndex: 'wl2', key: 'wl2', width: 100, render: renderCell },
    { title: 'GSL (psf) 0.8', dataIndex: 'gsl08', key: 'gsl08', width: 100, render: renderCell },
    { title: 'WL (mph) Cat. I', dataIndex: 'wl1', key: 'wl1', width: 100, render: renderCell },
  ]

  return (
    <div>
      <Table 
        dataSource={snowWindLoadData} 
        columns={columns} 
        pagination={false} 
      />
      {snowWindLoadData.length > 0 && (
        <div style={{marginTop: '30px'}}>
          {snowWindLoadData[0].notes.map((notes, idx) => (
            <p key={idx}><b>Note:</b> {notes}<br /></p>
          ))}
        </div>
      )}
    </div>
  ) 
}

export default function SnowWindLoad() {
  const [buildingType, setBuildingType] = useState('')
  const [buildingWidth, setBuildingWidth] = useState('')
  const [buildingInputsKey, setBuildingInputsKey] = useState(0)

  const onBuildingTypeChange = (type) => {
    setBuildingType(type)
    setBuildingWidth('')
    setBuildingInputsKey(prevKey => prevKey + 1)
  }

  const onBuildingWidthChange = (width) => {
    setBuildingWidth(width)
  }

  return (
    <div>
      <PageHeader title="Snow & Wind Load Charts" />
      <BuildingInputs
        key={buildingInputsKey}
        buildingType={buildingType}
        onBuildingTypeChange={onBuildingTypeChange}
        onBuildingWidthChange={onBuildingWidthChange}
      />
      <p>
      Advantage buildings only: This chart is to be used with the LC1 selection.
      </p>
      <SnowWindLoadChart 
        buildingType={buildingType} 
        buildingWidth={buildingWidth} 
      />
      <Button 
        style={{marginTop: '20px'}} 
        href="/static/county-load-chart.pdf"
        target="_blank">
        County Loading Link
      </Button>
      <p style={{marginTop: '30px'}}>
        *The use of low hazard versus standard hazard building category varies per the customer's
        use of the building. Generally accepted in the industry:  Cat II (1.0Imp snow) are used for commercial and medium-risk storage applications, while Cat I (0.8Imp snow) importance factors are used for agricultural and low-risk storage applications. Other higher building categories may have to be used on special case applications. Check with local jurisdiction for site specific importance factors needed. 4'-6' Center Reactions available upon request.		
      </p>
      <div style={{textAlign: 'center'}}>Confidential and Proprietary to Accu-Steel Inc.  Used with permission only.</div>
    </div>
  )
}



